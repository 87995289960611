
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LayoutDashboard, Settings, Folder, HelpCircle, Headset, Lock, FileSearch } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { LemonSqueezyPaymentDialog } from "./pricing/LemonSqueezyPaymentDialog";
import { pricingPlans } from "./pricing/pricingPlans";
import { useSubscription } from "@/hooks/useSubscription";
import { useToast } from "@/components/ui/use-toast";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showProUpgrade, setShowProUpgrade] = useState(false);
  const { data: subscription } = useSubscription();
  const { canAccessIndividual, canAccessPro } = usePlanFeatures();

  const getUpgradeConfig = () => {
    if (!subscription) {
      return {
        text: "Start 7-day free trial to unlock more data",
        buttonText: "Get Started",
        action: () => navigate('/pricing')
      };
    }

    switch (subscription.plan.toLowerCase()) {
      case 'individual':
        return {
          text: "Upgrade to pro to unlock all data",
          buttonText: "Upgrade to Pro",
          action: () => setShowProUpgrade(true)
        };
      default:
        return null;
    }
  };

  const upgradeConfig = getUpgradeConfig();
  const showUpgradeButton = upgradeConfig !== null;

  const handleUpgradeSuccess = () => {
    setShowProUpgrade(false);
    toast({
      title: "Success",
      description: "Your subscription has been updated.",
    });
    navigate("/dashboard");
  };

  return (
    <aside className="w-full md:w-64 bg-sidebar-background border-r border-sidebar-border p-4 flex flex-col">
      <nav className="flex-1 space-y-4">
        <Link 
          to="/dashboard"
          className={`flex items-center gap-2 ${
            location.pathname === '/dashboard' ? 'text-sidebar-primary' : 'text-sidebar-foreground'
          } hover:text-sidebar-primary`}
        >
          <LayoutDashboard className="w-5 h-5" />
          <span>Dashboard</span>
        </Link>

        <Link 
          to="/audit"
          className={`flex items-center gap-2 ${
            location.pathname === '/audit' ? 'text-sidebar-primary' : 'text-sidebar-foreground'
          } hover:text-sidebar-primary`}
        >
          <FileSearch className="w-5 h-5" />
          <span>Profile Audit</span>
        </Link>

        <Separator className="bg-sidebar-border" />

        <Link 
          to="/settings"
          className="flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary"
        >
          <Settings className="w-5 h-5" />
          <span>Settings</span>
        </Link>

        <Link 
          to="/help"
          className="flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary"
        >
          <HelpCircle className="w-5 h-5" />
          <span>Help</span>
        </Link>

        <Link 
          to="/support"
          className="flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary"
        >
          <Headset className="w-5 h-5" />
          <span>Support</span>
        </Link>
      </nav>
      
      {showUpgradeButton && (
        <div className="mt-6 space-y-4">
          <Button
            className="w-full bg-gradient-to-r from-orange-400 to-orange-500 hover:from-orange-500 hover:to-orange-600 text-white transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
            onClick={upgradeConfig.action}
          >
            {upgradeConfig.buttonText}
          </Button>
          <p className="text-xs text-muted-foreground text-center px-2">
            {upgradeConfig.text}
          </p>
        </div>
      )}

      {showProUpgrade && (
        <LemonSqueezyPaymentDialog
          plan={{
            name: pricingPlans[1].name,
            price: Number(pricingPlans[1].price),
            features: [...pricingPlans[1].features],
          }}
          billingPeriod="monthly"
          onSuccess={handleUpgradeSuccess}
          onClose={() => setShowProUpgrade(false)}
        />
      )}
    </aside>
  );
};
