
import { ArrowRight, Star, Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

export const Hero = () => {
  return (
    <div className="relative min-h-[80vh] flex items-center animate-fade-up">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-white" />
      <div className="container mx-auto px-4 relative">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 text-linkedin-primary">
            Uncover Your LinkedIn Impact
          </h1>
          <p className="text-xl md:text-2xl mb-12 text-[#F97316]">
            Measure, Analyze, Grow
          </p>
          <div className="max-w-3xl mx-auto">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <Input 
                  placeholder="Enter your LinkedIn profile URL..." 
                  className="h-14 text-lg pl-12 pr-4 transition-shadow hover:shadow-md focus:shadow-md"
                />
              </div>
              <Button 
                className="h-14 px-8 bg-[#F97316] hover:bg-[#EA580C] text-white font-semibold transform transition-all duration-200 hover:scale-105 hover:shadow-lg"
              >
                Start My Analysis
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </div>
            <div className="flex items-center justify-center mt-4 text-gray-600">
              <Star className="w-4 h-4 mr-2 text-[#F97316]" />
              <span>Get the answer in minutes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
