
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialog } from "@/components/ui/alert-dialog";
import { Link } from "react-router-dom";

interface ProjectSettingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
  projectUrl?: string;
  onSave: (newName: string) => void;
  onDelete: () => void;
}

export const ProjectSettingsDialog = ({
  isOpen,
  onClose,
  projectName,
  projectUrl = "example.com",
  onSave,
  onDelete
}: ProjectSettingsDialogProps) => {
  const [newProjectName, setNewProjectName] = useState(projectName);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { canAccessIndividual, canAccessPro } = usePlanFeatures();

  const handleSave = () => {
    if (newProjectName.trim()) {
      onSave(newProjectName);
      onClose();
    }
  };

  const confirmDelete = () => {
    setIsDeleteDialogOpen(false);
    onDelete();
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Project Settings</DialogTitle>
          </DialogHeader>
          
          <div className="space-y-6 py-4">
            <div className="space-y-2">
              <Label htmlFor="projectTitle">Project Title</Label>
              <Input
                id="projectTitle"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="domain">Domain</Label>
              <Input
                id="domain"
                value={projectUrl}
                readOnly
                className="bg-gray-50"
              />
            </div>
            
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-medium">Keywords</p>
                  <p className="text-sm text-gray-500">25/25 available</p>
                </div>
                {!canAccessPro && (
                  <Button variant="outline" size="sm" asChild>
                    <Link to="/pricing">Upgrade</Link>
                  </Button>
                )}
              </div>
              <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                <div className="h-full bg-orange-400 rounded-full" style={{ width: '100%' }}></div>
              </div>
            </div>

            <div className="space-y-4 border rounded-md p-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-medium">Rank Tracking Frequency</p>
                  <p className="text-sm text-gray-500">
                    {canAccessIndividual ? "Daily updates" : "Weekly updates"}
                  </p>
                </div>
                
                <div className="flex items-center gap-2">
                  <span>Weekly</span>
                  <Switch checked={canAccessIndividual} disabled={!canAccessIndividual} />
                  <span>Daily</span>
                </div>
              </div>
              
              {!canAccessIndividual && (
                <Button variant="link" className="text-orange-500 p-0 h-auto" asChild>
                  <Link to="/pricing">Upgrade to get daily rank tracking</Link>
                </Button>
              )}
            </div>

            <Button 
              variant="link" 
              className="text-red-500 p-0 h-auto w-auto" 
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Remove this project
            </Button>
          </div>
          
          <DialogFooter className="sm:justify-between">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="button" onClick={handleSave}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the project "{projectName}".
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDelete} className="bg-red-600 hover:bg-red-700">
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
