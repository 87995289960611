
import { SignIn } from "@clerk/clerk-react";
import { useLocation } from "react-router-dom";

export const PricingSignIn = () => {
  const location = useLocation();
  const { selectedPlan, billingPeriod } = location.state || {};

  // Preserve plan data in URL for after sign-in
  const encodedPlan = selectedPlan ? encodeURIComponent(JSON.stringify(selectedPlan)) : '';
  const redirectURL = `/callback?plan=${encodedPlan}&billing=${billingPeriod || 'monthly'}`;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <SignIn
        redirectUrl={redirectURL}
        appearance={{
          elements: {
            formButtonPrimary: 'bg-linkedin-primary hover:bg-linkedin-dark',
            card: 'rounded-lg shadow-md max-w-md w-full',
            headerTitle: 'text-2xl font-bold text-gray-900',
            headerSubtitle: 'text-gray-600',
            socialButtonsBlockButton: 'border border-gray-300 hover:bg-gray-50',
            formFieldInput: 'rounded border-gray-300 focus:border-linkedin-primary focus:ring-linkedin-primary',
            footerActionLink: 'text-linkedin-primary hover:text-linkedin-dark',
            rootBox: 'w-full max-w-md'
          }
        }}
      />
    </div>
  );
};
