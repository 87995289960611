
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BrightDataInfo } from "@/components/brightdata/BrightDataInfo";
import { BrightDataApiStatus } from "@/components/brightdata/BrightDataApiStatus";
import { BrightDataUsageInfo } from "@/components/brightdata/BrightDataUsageInfo";
import { BrightDataConnectionTest } from "@/components/brightdata/BrightDataConnectionTest";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const BrightDataGuide = () => {
  const [isTesting, setIsTesting] = useState(false);
  const [keyValid, setKeyValid] = useState<boolean | null>(null);
  
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>BrightData API Status</CardTitle>
          <BrightDataInfo />
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="status">
            <TabsList className="mb-4">
              <TabsTrigger value="status">API Status</TabsTrigger>
              <TabsTrigger value="usage">Usage Info</TabsTrigger>
              <TabsTrigger value="debug">Debug Tools</TabsTrigger>
            </TabsList>
            
            <TabsContent value="status">
              <BrightDataApiStatus 
                keyValid={keyValid}
                isTesting={isTesting}
                setIsTesting={setIsTesting}
                setKeyValid={setKeyValid}
              />
            </TabsContent>
            
            <TabsContent value="usage">
              <BrightDataUsageInfo />
            </TabsContent>
            
            <TabsContent value="debug">
              <BrightDataConnectionTest />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};
