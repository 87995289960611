
import { useState, useEffect } from "react";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { UpgradePrompt } from "./UpgradePrompt";
import { PlanOverview } from "./sections/PlanOverview";
import { ContentPerformance } from "./sections/ContentPerformance";
import { FollowerGrowth } from "./sections/FollowerGrowth";
import { OpportunitiesSection } from "./sections/OpportunitiesSection";
import { WeeklyPerformance } from "./sections/WeeklyPerformance";
import { RecentPostsList } from "./sections/RecentPostsList";
import { AudienceInsights } from "./sections/AudienceInsights";
import { DashboardHeader } from "./DashboardHeader";
import { Loader2 } from "lucide-react";

interface DashboardContentProps {
  projectName: string;
  profileData?: any;
  linkedInData?: any;
  isLoading?: boolean;
}

export const DashboardContent = ({ 
  projectName, 
  profileData, 
  linkedInData, 
  isLoading = false 
}: DashboardContentProps) => {
  const { userPlan, canAccessIndividual, canAccessPro } = usePlanFeatures();
  
  // Use real data if available, otherwise fallback to sample data
  const [followerCount, setFollowerCount] = useState(3865);
  const [followerChange] = useState(12.8);
  const [engagementRate, setEngagementRate] = useState(4.7);
  const [engagementChange] = useState(2.1);
  const [contentReach, setContentReach] = useState(24582);
  const [contentReachChange] = useState(15.3);
  const [recentPosts, setRecentPosts] = useState([
    {
      id: 1,
      date: "2025-02-28",
      content: "Excited to announce our new product launch next week! #innovation #tech",
      engagement: 241,
      likes: 182,
      comments: 43,
      shares: 16,
      type: "text"
    },
    {
      id: 2,
      date: "2025-02-25",
      content: "Check out my latest article on AI trends for 2025",
      engagement: 315,
      likes: 245,
      comments: 52,
      shares: 18,
      type: "image"
    },
    {
      id: 3,
      date: "2025-02-22",
      content: "Had a great time speaking at the Tech Conference today",
      engagement: 487,
      likes: 392,
      comments: 67,
      shares: 28,
      type: "video"
    },
    {
      id: 4,
      date: "2025-02-18",
      content: "Sharing some insights from our recent customer research",
      engagement: 178,
      likes: 143,
      comments: 28,
      shares: 7,
      type: "text"
    }
  ]);

  // Weekly metrics data
  const [weeklyMetrics] = useState([
    { day: "Mon", posts: 2, engagement: 187 },
    { day: "Tue", posts: 1, engagement: 315 },
    { day: "Wed", posts: 0, engagement: 124 },
    { day: "Thu", posts: 3, engagement: 356 },
    { day: "Fri", posts: 1, engagement: 221 },
    { day: "Sat", posts: 0, engagement: 98 },
    { day: "Sun", posts: 0, engagement: 76 }
  ]);

  // Update state when LinkedIn data is loaded
  useEffect(() => {
    if (linkedInData) {
      // Update follower count if available
      if (linkedInData.profile_data?.basicInfo?.followers) {
        setFollowerCount(linkedInData.profile_data.basicInfo.followers);
      }

      // Update engagement rate if available
      if (linkedInData.growth_metrics?.avg_engagement_rate) {
        setEngagementRate(linkedInData.growth_metrics.avg_engagement_rate);
      }

      // Update content reach if available
      if (linkedInData.growth_metrics?.total_likes) {
        setContentReach(linkedInData.growth_metrics.total_likes);
      }

      // Update recent posts if available
      if (linkedInData.posts_data && linkedInData.posts_data.length > 0) {
        const formattedPosts = linkedInData.posts_data.map((post: any, index: number) => ({
          id: index + 1,
          date: post.date,
          content: post.content,
          engagement: post.likes + post.comments + post.shares,
          likes: post.likes,
          comments: post.comments,
          shares: post.shares,
          type: post.type || "text"
        }));
        setRecentPosts(formattedPosts.slice(0, 4)); // Get the 4 most recent posts
      }
    }
  }, [linkedInData]);
  
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-[60vh]">
        <Loader2 className="w-12 h-12 animate-spin text-primary mb-4" />
        <p className="text-lg text-muted-foreground">Loading LinkedIn data...</p>
      </div>
    );
  }
  
  return (
    <div className="space-y-8">
      <DashboardHeader projectName={projectName} />
      
      <PlanOverview 
        followerCount={followerCount}
        followerChange={followerChange}
        engagementRate={engagementRate}
        engagementChange={engagementChange}
        contentReach={contentReach}
        contentReachChange={contentReachChange}
        canAccessIndividual={canAccessIndividual}
      />
      
      <ContentPerformance canAccessIndividual={canAccessIndividual} />
      
      <FollowerGrowth canAccessIndividual={canAccessIndividual} />
      
      <OpportunitiesSection canAccessPro={canAccessPro} />

      {/* Weekly Performance with actual charts */}
      <WeeklyPerformance weeklyMetrics={weeklyMetrics} />
      
      {/* Recent Posts */}
      <RecentPostsList recentPosts={recentPosts} />
      
      {/* Audience Insights */}
      <AudienceInsights />
    </div>
  );
};
