
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { AlertCircle, CheckCircle, RefreshCw } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/components/ui/use-toast";

const BrightDataTest = () => {
  const [apiKey, setApiKey] = useState("");
  const [profileUrl, setProfileUrl] = useState("https://www.linkedin.com/in/gal-mor/");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [requestDetails, setRequestDetails] = useState<any>(null);
  const [responseHeaders, setResponseHeaders] = useState<any>(null);
  const { toast } = useToast();

  // Load API key from localStorage on component mount
  useEffect(() => {
    const savedApiKey = localStorage.getItem("brightdata_api_key");
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, []);

  const testBrightDataApi = async () => {
    if (!apiKey) {
      setError("Please enter your BrightData API key");
      return;
    }

    // IMPORTANT: Trim whitespace from API key - exactly as in Postman
    const trimmedApiKey = apiKey.trim();
    
    setIsLoading(true);
    setError(null);
    setResult(null);
    setRequestDetails(null);
    setResponseHeaders(null);

    try {
      console.log("Starting BrightData API test via Edge Function...");
      console.log("API Key length:", trimmedApiKey.length);
      console.log("API Key starts with:", trimmedApiKey.substring(0, 4) + "...");
      console.log("API Key ends with:", "..." + trimmedApiKey.substring(trimmedApiKey.length - 4));
      
      // Call the Supabase Edge Function
      const edgeFunctionUrl = import.meta.env.VITE_SUPABASE_URL 
        ? `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/brightdata-test-direct`
        : "https://dnwgkklwadvoxfbospfh.supabase.co/functions/v1/brightdata-test-direct";
      
      console.log("Using edge function URL:", edgeFunctionUrl);
      
      const response = await fetch(edgeFunctionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          apiKey: trimmedApiKey,
          url: profileUrl
        }),
      });

      // Store request details for debugging
      const requestInfo = {
        url: edgeFunctionUrl,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          apiKey: trimmedApiKey ? `${trimmedApiKey.substring(0, 4)}...${trimmedApiKey.substring(trimmedApiKey.length - 4)}` : "",
          url: profileUrl
        })
      };
      
      setRequestDetails(requestInfo);
      
      // Capture response headers for debugging
      const headerData = {};
      response.headers.forEach((value, key) => {
        headerData[key] = value;
      });
      setResponseHeaders(headerData);
      
      console.log("Edge function response status:", response.status);
      console.log("Edge function response headers:", headerData);

      const responseText = await response.text();
      console.log("Response text:", responseText);
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error("Error parsing response:", e);
        throw new Error(`Failed to parse response: ${responseText.substring(0, 100)}...`);
      }
      
      console.log("Edge function response data:", data);
      
      if (data.success) {
        toast({
          title: "API Test Successful",
          description: data.message || "Successfully connected to BrightData API",
        });
      } else {
        toast({
          title: "API Test Failed",
          description: data.error || "Failed to connect to BrightData API",
          variant: "destructive",
        });
      }
      
      setResult(data);
    } catch (err) {
      console.error("Error testing BrightData API:", err);
      setError(err instanceof Error ? err.message : "Unknown error occurred");
      
      toast({
        title: "Error",
        description: err instanceof Error ? err.message : "Unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveApiKey = () => {
    if (!apiKey) {
      setError("Please enter your BrightData API key");
      return;
    }
    
    const trimmedApiKey = apiKey.trim();
    localStorage.setItem("brightdata_api_key", trimmedApiKey);
    
    toast({
      title: "API Key Saved",
      description: "Your BrightData API key has been saved to local storage.",
    });
  };

  const resetApiKey = () => {
    localStorage.removeItem("brightdata_api_key");
    localStorage.removeItem("brightdata_profiles_api_key");
    localStorage.removeItem("brightdata_posts_api_key");
    
    setApiKey("");
    
    toast({
      title: "API Key Removed",
      description: "Your BrightData API key has been removed. LinkedIn data will not be available until you add a new key.",
    });
  };

  return (
    <div className="container py-8 max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">BrightData API Test</h1>
      <p className="mb-8 text-gray-600">
        This test page debugs the connection between the Edge Function and the BrightData API.
      </p>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>API Configuration</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div>
            <label htmlFor="apiKey" className="block text-sm font-medium mb-1">
              BrightData API Key
            </label>
            <Input
              id="apiKey"
              type="password"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Enter your BrightData API key"
              className="font-mono"
            />
            <p className="text-xs text-gray-500 mt-1">
              Enter your complete BrightData API key exactly as it appears in your BrightData account
            </p>
          </div>

          <div>
            <label htmlFor="profileUrl" className="block text-sm font-medium mb-1">
              LinkedIn Profile URL
            </label>
            <Input
              id="profileUrl"
              value={profileUrl}
              onChange={(e) => setProfileUrl(e.target.value)}
              placeholder="https://www.linkedin.com/in/username/"
            />
          </div>

          <div className="flex space-x-3">
            <Button 
              onClick={testBrightDataApi} 
              disabled={isLoading}
              className="gap-2"
            >
              {isLoading ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  Testing...
                </>
              ) : (
                "Test Edge Function"
              )}
            </Button>
            
            <Button 
              onClick={saveApiKey} 
              variant="outline"
              disabled={isLoading || !apiKey}
            >
              Save API Key
            </Button>
            
            <Button 
              onClick={resetApiKey} 
              variant="outline"
              disabled={isLoading}
            >
              Reset API Key
            </Button>
          </div>
        </CardContent>
      </Card>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription className="ml-2">{error}</AlertDescription>
        </Alert>
      )}

      {requestDetails && (
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>Request Details</CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea
              value={JSON.stringify(requestDetails, null, 2)}
              readOnly
              className="h-[200px] font-mono text-sm"
            />
          </CardContent>
        </Card>
      )}

      {responseHeaders && (
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>Response Headers</CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea
              value={JSON.stringify(responseHeaders, null, 2)}
              readOnly
              className="h-[200px] font-mono text-sm"
            />
          </CardContent>
        </Card>
      )}

      {result && (
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              {result.success ? (
                <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
              ) : (
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
              )}
              API Test Result
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea
              value={JSON.stringify(result, null, 2)}
              readOnly
              className="h-[400px] font-mono text-sm"
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default BrightDataTest;
