
import { Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface UpgradePromptProps {
  title: string;
  description: string;
  plan: string;
  className?: string;
}

export const UpgradePrompt = ({ 
  title, 
  description, 
  plan, 
  className = "" 
}: UpgradePromptProps) => {
  const navigate = useNavigate();
  
  return (
    <div className={`bg-gradient-to-br from-orange-50 to-amber-50 border border-orange-100 rounded-lg p-6 ${className}`}>
      <div className="flex items-start gap-4">
        <div className="bg-orange-100 p-2 rounded-full">
          <Sparkles className="h-5 w-5 text-orange-500" />
        </div>
        <div className="flex-1">
          <h3 className="font-semibold text-lg">{title}</h3>
          <p className="text-sm text-gray-600 mt-1 mb-4">{description}</p>
          <Button 
            onClick={() => navigate('/pricing')} 
            className="bg-orange-500 hover:bg-orange-600 text-white"
          >
            Upgrade to {plan}
          </Button>
        </div>
      </div>
    </div>
  );
};
