
import { useState } from "react";
import { BarChart2, LineChart, Award, TrendingUp, FileDown, FileText } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LockedFeature } from "../LockedFeature";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

interface OpportunitiesSectionProps {
  canAccessPro: boolean;
}

export const OpportunitiesSection = ({ canAccessPro }: OpportunitiesSectionProps) => {
  const { toast } = useToast();
  
  const handleExport = (format: string) => {
    toast({
      title: `Export ${format.toUpperCase()} Started`,
      description: `Your ${format.toUpperCase()} report is being generated and will download shortly.`,
    });
    
    // Simulate download delay
    setTimeout(() => {
      toast({
        title: `${format.toUpperCase()} Report Ready`,
        description: `Your ${format.toUpperCase()} report has been downloaded successfully.`,
      });
    }, 2000);
  };
  
  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Top Opportunities</h2>
        
        {canAccessPro && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex gap-2 items-center">
                <FileDown className="h-4 w-4" />
                <span>Export Report</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-40 bg-white">
              <DropdownMenuItem onClick={() => handleExport('pdf')} className="cursor-pointer">
                <FileText className="h-4 w-4 mr-2" />
                <span>PDF Format</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleExport('csv')} className="cursor-pointer">
                <FileText className="h-4 w-4 mr-2" />
                <span>CSV Format</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      
      {canAccessPro ? (
        <Card>
          <CardContent className="pt-6">
            <div className="space-y-4">
              <div className="p-4 border rounded-md">
                <h3 className="font-medium flex items-center">
                  <BarChart2 className="h-5 w-5 mr-2 text-orange-500" />
                  Competitor Benchmarking
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Your engagement rate is 2.1% higher than your top competitors, but your posting frequency is lower. 
                  Consider increasing your posting cadence to 3-4 times per week to maximize your advantage.
                </p>
              </div>
              <div className="p-4 border rounded-md">
                <h3 className="font-medium flex items-center">
                  <LineChart className="h-5 w-5 mr-2 text-orange-500" />
                  Content Strategy
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Posts containing "leadership" and "innovation" keywords perform 35% better than your average content.
                  Consider focusing on these topics and including more industry insights to boost engagement further.
                </p>
              </div>
              <div className="p-4 border rounded-md">
                <h3 className="font-medium flex items-center">
                  <Award className="h-5 w-5 mr-2 text-orange-500" />
                  Profile Optimization
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Adding featured content to your profile can increase visibility by up to 38%. Consider showcasing 
                  your recent achievements, publications, or case studies in your featured section.
                </p>
              </div>
              <div className="p-4 border rounded-md">
                <h3 className="font-medium flex items-center">
                  <TrendingUp className="h-5 w-5 mr-2 text-orange-500" />
                  Audience Growth
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Your audience has grown 12.8% in the last month, which is excellent. To maintain this growth rate,
                  try engaging more with comments on your posts and responding to mentions within 24 hours.
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <LockedFeature
          title="AI-Powered Opportunities"
          description="Get personalized recommendations based on competitor analysis and your content performance."
          requiredPlan="Pro"
          className="h-64"
        />
      )}
    </div>
  );
};
