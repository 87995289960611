
import { Button } from "@/components/ui/button";
import { Copy } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const BrightDataIntegration = () => {
  const { toast } = useToast();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Code copied to clipboard",
      description: "You can now paste the code in your editor",
    });
  };

  return (
    <div className="space-y-8">
      <section className="space-y-4">
        <h2 className="text-2xl font-bold">Setup & Configuration</h2>
        <p className="text-gray-700">
          To integrate Bright Data with TrackInfluence, you'll need to obtain your Web Scraper API key from your Bright Data account dashboard.
          Then use the following API endpoints for LinkedIn data:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>LinkedIn People Profiles: <code className="bg-gray-100 px-2 py-1 rounded text-pink-600">https://web.brightdata.com/api/social_media/linkedin/profiles</code></li>
          <li>LinkedIn Posts: <code className="bg-gray-100 px-2 py-1 rounded text-pink-600">https://web.brightdata.com/api/social_media/linkedin/posts</code></li>
        </ul>
      </section>

      <section className="space-y-4">
        <h2 className="text-2xl font-bold">Python Code Example</h2>
        <div className="bg-gray-900 text-gray-100 p-4 rounded-md relative">
          <Button 
            size="sm"
            variant="ghost" 
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
            onClick={() => copyToClipboard(`
import requests
import json

# ---------------------- CONFIGURATION ----------------------
API_KEY = "YOUR_BRIGHTDATA_API_KEY"  # Replace with your Bright Data API key
LINKEDIN_PROFILE_URL = "https://www.linkedin.com/in/your_target_profile"  # LinkedIn profile to target
NUM_POSTS = 5  # Number of posts to retrieve
# ---------------------- END CONFIGURATION -------------------

# === Function to Fetch LinkedIn Profile Data ===
def get_linkedin_profile(profile_url, api_key):
    """Fetches LinkedIn profile data using the Bright Data API."""
    url = "https://web.brightdata.com/api/social_media/linkedin/profiles"
    params = {
        "api_key": api_key,
        "url": profile_url,
    }
    try:
        response = requests.get(url, params=params)
        response.raise_for_status()  # Raise HTTPError for bad responses
        data = response.json()
        return data
    except requests.exceptions.RequestException as e:
        print(f"Error fetching profile data: {e}")
        return None

# === Function to Fetch LinkedIn Posts Data ===
def get_linkedin_posts(profile_url, api_key):
    """Fetches LinkedIn posts data using the Bright Data API."""
    url = "https://web.brightdata.com/api/social_media/linkedin/posts"
    params = {
        "api_key": api_key,
        "discover_by_profile_url": profile_url,
    }
    try:
        response = requests.get(url, params=params)
        response.raise_for_status()  # Raise HTTPError for bad responses
        data = response.json()
        return data
    except requests.exceptions.RequestException as e:
        print(f"Error fetching posts data: {e}")
        return None

# === Main Execution ===
if __name__ == "__main__":
    profile_data = get_linkedin_profile(LINKEDIN_PROFILE_URL, API_KEY)
    posts_data = get_linkedin_posts(LINKEDIN_PROFILE_URL, API_KEY)

    if profile_data:
        print("\\n--- LinkedIn Profile Data ---")
        print(json.dumps(profile_data, indent=4))  # Pretty-print the JSON

    if posts_data:
        print("\\n--- LinkedIn Posts Data ---")
        print(json.dumps(posts_data, indent=4))  # Pretty-print the JSON`)}
          >
            <Copy className="h-4 w-4" />
          </Button>
          <pre className="overflow-x-auto text-sm">
{`import requests
import json

# ---------------------- CONFIGURATION ----------------------
API_KEY = "YOUR_BRIGHTDATA_API_KEY"  # Replace with your Bright Data API key
LINKEDIN_PROFILE_URL = "https://www.linkedin.com/in/your_target_profile"  # LinkedIn profile to target
NUM_POSTS = 5  # Number of posts to retrieve
# ---------------------- END CONFIGURATION -------------------

# === Function to Fetch LinkedIn Profile Data ===
def get_linkedin_profile(profile_url, api_key):
    """Fetches LinkedIn profile data using the Bright Data API."""
    url = "https://web.brightdata.com/api/social_media/linkedin/profiles"
    params = {
        "api_key": api_key,
        "url": profile_url,
    }
    try:
        response = requests.get(url, params=params)
        response.raise_for_status()  # Raise HTTPError for bad responses
        data = response.json()
        return data
    except requests.exceptions.RequestException as e:
        print(f"Error fetching profile data: {e}")
        return None

# === Function to Fetch LinkedIn Posts Data ===
def get_linkedin_posts(profile_url, api_key):
    """Fetches LinkedIn posts data using the Bright Data API."""
    url = "https://web.brightdata.com/api/social_media/linkedin/posts"
    params = {
        "api_key": api_key,
        "discover_by_profile_url": profile_url,
    }
    try:
        response = requests.get(url, params=params)
        response.raise_for_status()  # Raise HTTPError for bad responses
        data = response.json()
        return data
    except requests.exceptions.RequestException as e:
        print(f"Error fetching posts data: {e}")
        return None

# === Main Execution ===
if __name__ == "__main__":
    profile_data = get_linkedin_profile(LINKEDIN_PROFILE_URL, API_KEY)
    posts_data = get_linkedin_posts(LINKEDIN_PROFILE_URL, API_KEY)

    if profile_data:
        print("\\n--- LinkedIn Profile Data ---")
        print(json.dumps(profile_data, indent=4))  # Pretty-print the JSON

    if posts_data:
        print("\\n--- LinkedIn Posts Data ---")
        print(json.dumps(posts_data, indent=4))  # Pretty-print the JSON`}
          </pre>
        </div>
      </section>

      <section className="space-y-4">
        <h2 className="text-2xl font-bold">Data Extraction & Usage</h2>
        <p className="text-gray-700">
          After fetching the data, you'll need to extract the specific data points that you want to use in your TrackInfluence dashboard and audit reports. 
          Here's an example of how to extract profile name, follower count, and experience:
        </p>
        
        <div className="bg-gray-900 text-gray-100 p-4 rounded-md relative">
          <Button 
            size="sm"
            variant="ghost" 
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
            onClick={() => copyToClipboard(`
# Access data from People Profiles API
if profile_data and len(profile_data) > 0:
    profile = profile_data[0]  # The API returns a list containing one dictionary
    name = profile.get("name")
    followers = profile.get("followers")
    experience = profile.get("experience")  # list of experience entries
    print (f"Name:{name}, Follers: {followers}")

    # Iterate over experience
    for job in experience:
        company_name = job.get("company")
        title = job.get("title")
        duration = job.get("duration")
        print (f"Company:{company_name}, Title: {title}, Duration: {duration}")

# Access data from Posts API
if posts_data and len(posts_data) > 0:
    for post in posts_data:
        post_id = post.get("id")
        post_text = post.get("post_text")
        num_likes = post.get("num_likes")
        print (f"Post ID:{post_id}, Post Text: {post_text}, likes: {num_likes}")`)}
          >
            <Copy className="h-4 w-4" />
          </Button>
          <pre className="overflow-x-auto text-sm">
{`# Access data from People Profiles API
if profile_data and len(profile_data) > 0:
    profile = profile_data[0]  # The API returns a list containing one dictionary
    name = profile.get("name")
    followers = profile.get("followers")
    experience = profile.get("experience")  # list of experience entries
    print (f"Name:{name}, Follers: {followers}")

    # Iterate over experience
    for job in experience:
        company_name = job.get("company")
        title = job.get("title")
        duration = job.get("duration")
        print (f"Company:{company_name}, Title: {title}, Duration: {duration}")

# Access data from Posts API
if posts_data and len(posts_data) > 0:
    for post in posts_data:
        post_id = post.get("id")
        post_text = post.get("post_text")
        num_likes = post.get("num_likes")
        print (f"Post ID:{post_id}, Post Text: {post_text}, likes: {num_likes}")`}
          </pre>
        </div>
      </section>

      <section className="space-y-4">
        <h2 className="text-2xl font-bold">Important Notes</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li><strong>Error Handling:</strong> The code includes basic error handling for network issues. Consider adding more robust error handling (e.g., logging, retries) for production use.</li>
          <li><strong>Rate Limiting:</strong> Be mindful of Bright Data's API usage limits and implement appropriate rate limiting in your code. Contact Bright Data support for information.</li>
          <li><strong>Asynchronous Requests:</strong> For improved performance, especially when making multiple API calls, consider using asynchronous requests with the asyncio and aiohttp libraries.</li>
          <li><strong>Data Validation:</strong> Validate the data that you receive from the API to ensure that it meets your expectations.</li>
          <li><strong>Caching:</strong> Implement caching strategies to reduce API requests and improve performance.</li>
        </ul>
      </section>

      <section className="bg-blue-50 p-6 rounded-lg border border-blue-100">
        <h2 className="text-xl font-semibold text-blue-800 mb-3">Need Help?</h2>
        <p className="text-blue-700 mb-4">
          If you're having trouble implementing BrightData integration with TrackInfluence, our team is here to help.
        </p>
        <Button className="bg-blue-600 hover:bg-blue-700">Contact Support</Button>
      </section>
    </div>
  );
};
