
import { X } from "lucide-react";
import { PaymentSuccess } from "./PaymentSuccess";
import { useSearchParams } from "react-router-dom";
import { format, addDays } from "date-fns";
import { useLemonSqueezyCheckout } from "./lemonsqueezy/useLemonSqueezyCheckout";
import { useLemonSqueezyStyles } from "./lemonsqueezy/LemonSqueezyStyles";

interface LemonSqueezyPaymentDialogProps {
  plan: {
    name: string;
    price: number;
    features: string[];
  };
  billingPeriod: "monthly" | "lifetime";
  onSuccess: () => void;
  onClose: () => void;
}

export const LemonSqueezyPaymentDialog = ({
  plan,
  billingPeriod,
  onSuccess,
  onClose,
}: LemonSqueezyPaymentDialogProps) => {
  const [searchParams] = useSearchParams();
  const isCheckoutSuccess = searchParams.get('checkout') === 'success';
  const discountCode = searchParams.get('discount')?.toLowerCase();

  // Initialize LemonSqueezy checkout
  useLemonSqueezyCheckout({
    plan,
    billingPeriod,
    onClose,
    isCheckoutSuccess,
  });

  // Apply LemonSqueezy styles
  useLemonSqueezyStyles();

  // Adjust trial period based on discount code
  const getTrialDays = () => {
    switch (discountCode) {
      case 'launch':
        return 60;
      case 'special':
        return 30;
      default:
        return 7;
    }
  };

  const firstPaymentDate = format(addDays(new Date(), getTrialDays()), 'MMMM do, yyyy');

  return isCheckoutSuccess ? (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6 relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
        <PaymentSuccess 
          firstPaymentDate={firstPaymentDate}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  ) : null;
};
