
import { UserProfile } from "@clerk/clerk-react";
import { Header } from "@/components/Header";

const UserProfileLayout = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1 container mx-auto px-4 py-8">
        <UserProfile />
      </div>
    </div>
  );
};

export default UserProfileLayout;
