
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Sidebar } from "@/components/Sidebar";
import { TimeframeBanner } from "@/components/linkedin/TimeframeBanner";
import { PostsHeader } from "@/components/posts/PostsHeader";
import { PostsTable } from "@/components/posts/PostsTable";
import { Post } from "@/types/post";
import { useToast } from "@/components/ui/use-toast";

const posts: Post[] = [
  {
    date: "2024-03-01",
    title: "How to Increase Your LinkedIn Engagement",
    type: "Video",
    views: 12500,
    engagement: 8.5,
    likes: 450,
    comments: 85,
    shares: 120,
  },
  {
    date: "2024-02-28",
    title: "Weekly Update",
    type: "Text",
    views: 2300,
    engagement: 2.1,
    likes: 120,
    comments: 15,
    shares: 8,
  },
  {
    date: "2024-02-27",
    title: "Team Building Event",
    type: "Image",
    views: 5600,
    engagement: 5.4,
    likes: 230,
    comments: 45,
    shares: 25,
  },
];

const AllPosts = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [timeframe, setTimeframe] = useState({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
    end: new Date().toLocaleDateString(),
  });
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Post;
    direction: 'asc' | 'desc';
  }>({ key: 'date', direction: 'desc' });

  const handleTimeframeChange = (value: string) => {
    const now = new Date();
    let start = new Date();
    
    switch (value) {
      case "30d":
        start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "90d":
        start = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
        break;
      case "6m":
        start = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000);
        break;
      case "1y":
        start = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
        break;
    }

    setTimeframe({
      start: start.toLocaleDateString(),
      end: now.toLocaleDateString(),
    });
  };

  const handleBack = () => {
    toast({
      title: "Navigation",
      description: "Returning to report...",
    });
    navigate('/', { 
      state: { 
        returnToReport: true,
        timeframe 
      }
    });
  };

  const sortedPosts = [...posts].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleRequestSort = (key: keyof Post) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    });
  };

  return (
    <div className="flex flex-1">
      <Sidebar />
      <main className="flex-1 bg-gradient-to-br from-white to-blue-50">
        <div className="container mx-auto py-8 px-4 space-y-6">
          <PostsHeader onBack={handleBack} />

          <TimeframeBanner 
            {...timeframe}
            isPro={true}
            onTimeframeChange={handleTimeframeChange}
          />

          <Card className="p-6">
            <h1 className="text-2xl font-bold mb-6">All LinkedIn Posts</h1>
            <PostsTable 
              posts={sortedPosts}
              sortConfig={sortConfig}
              onRequestSort={handleRequestSort}
            />
          </Card>
        </div>
      </main>
    </div>
  );
};

export default AllPosts;
