
import { ArrowUp, ArrowDown, Lock } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LockedFeatureCard } from "../LockedFeatureCard";

interface PlanOverviewProps {
  followerCount: number;
  followerChange: number;
  engagementRate: number;
  engagementChange: number;
  contentReach: number;
  contentReachChange: number;
  canAccessIndividual: boolean;
}

export const PlanOverview = ({
  followerCount,
  followerChange,
  engagementRate,
  engagementChange,
  contentReach,
  contentReachChange,
  canAccessIndividual
}: PlanOverviewProps) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium text-muted-foreground">Followers</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{followerCount.toLocaleString()}</div>
            {canAccessIndividual ? (
              <div className="flex items-center mt-1">
                {followerChange > 0 ? (
                  <ArrowUp className="h-4 w-4 text-green-500 mr-1" />
                ) : (
                  <ArrowDown className="h-4 w-4 text-red-500 mr-1" />
                )}
                <span className={followerChange > 0 ? "text-green-500" : "text-red-500"}>
                  {Math.abs(followerChange)}%
                </span>
                <span className="text-muted-foreground text-sm ml-1">vs last month</span>
              </div>
            ) : (
              <div className="flex items-center mt-1 text-muted-foreground">
                <Lock className="h-3 w-3 mr-1" />
                <span className="text-sm">Upgrade to see change metrics</span>
              </div>
            )}
          </CardContent>
        </Card>
        
        {canAccessIndividual ? (
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Engagement Rate</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{typeof engagementRate === 'number' ? engagementRate.toFixed(1) : engagementRate}%</div>
              <div className="flex items-center mt-1">
                {engagementChange > 0 ? (
                  <ArrowUp className="h-4 w-4 text-green-500 mr-1" />
                ) : (
                  <ArrowDown className="h-4 w-4 text-red-500 mr-1" />
                )}
                <span className={engagementChange > 0 ? "text-green-500" : "text-red-500"}>
                  {Math.abs(engagementChange)}%
                </span>
                <span className="text-muted-foreground text-sm ml-1">vs last month</span>
              </div>
            </CardContent>
          </Card>
        ) : (
          <LockedFeatureCard
            title="Engagement Rate"
            description="See how your content is performing with engagement metrics"
            requiredPlan="Individual"
          />
        )}
        
        {canAccessIndividual ? (
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Content Reach</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{contentReach.toLocaleString()}</div>
              <div className="flex items-center mt-1">
                {contentReachChange > 0 ? (
                  <ArrowUp className="h-4 w-4 text-green-500 mr-1" />
                ) : (
                  <ArrowDown className="h-4 w-4 text-red-500 mr-1" />
                )}
                <span className={contentReachChange > 0 ? "text-green-500" : "text-red-500"}>
                  {Math.abs(contentReachChange)}%
                </span>
                <span className="text-muted-foreground text-sm ml-1">vs last month</span>
              </div>
            </CardContent>
          </Card>
        ) : (
          <LockedFeatureCard
            title="Content Reach"
            description="Discover how far your content is reaching"
            requiredPlan="Individual"
          />
        )}
      </div>
    </>
  );
};
