
import { Pricing } from "@/components/Pricing";

const PricingPage = () => {
  return (
    <main className="flex-1 overflow-auto">
      <Pricing />
    </main>
  );
};

export default PricingPage;
