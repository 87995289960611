import { Footer } from "@/components/Footer";

const Privacy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1 container mx-auto px-4 py-8 prose prose-slate max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">📜 Privacy Policy – TrackInfluence</h1>
        
        <p className="text-gray-600 mb-8">Effective Date: March 14, 2024</p>

        <p>At TrackInfluence, we respect your privacy and are committed to protecting your personal data. This Privacy Policy explains what information we collect, how we use it, and your rights.</p>

        <h2 className="text-2xl font-semibold mt-8">1. What Data We Collect</h2>
        <p>TrackInfluence collects:</p>
        <ul>
          <li>✅ User Account Information – Name, email, and authentication data (via Clerk.com).</li>
          <li>✅ LinkedIn Profile Data – Public LinkedIn data such as profile name, posts, followers, and engagement statistics.</li>
          <li>✅ Payment Information – Processed securely through Gumroad (we do not store payment details).</li>
          <li>✅ Usage Data – IP address, browser type, and analytics to improve our service.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">2. How We Use Your Data</h2>
        <p>We use collected data to:</p>
        <ul>
          <li>✅ Provide and improve TrackInfluence services.</li>
          <li>✅ Process payments and manage subscriptions.</li>
          <li>✅ Analyze LinkedIn engagement and generate reports.</li>
          <li>✅ Respond to customer support inquiries.</li>
        </ul>

        <p>We NEVER:</p>
        <ul>
          <li>❌ Sell or share your data with third-party advertisers.</li>
          <li>❌ Store private LinkedIn messages or non-public information.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">3. Cookies & Tracking Technologies</h2>
        <p>We use cookies to:</p>
        <ul>
          <li>✅ Keep users signed in.</li>
          <li>✅ Track app performance and analytics.</li>
          <li>✅ Store user preferences.</li>
        </ul>
        <p>Users can disable cookies via browser settings.</p>

        <h2 className="text-2xl font-semibold mt-8">4. Third-Party Services</h2>
        <p>TrackInfluence integrates with:</p>
        <ul>
          <li>✅ Clerk.com (authentication)</li>
          <li>✅ BrightData (LinkedIn public data fetching)</li>
          <li>✅ Gumroad (payments & subscriptions)</li>
        </ul>
        <p>Each provider has its own privacy policy, and we encourage you to review them.</p>

        <h2 className="text-2xl font-semibold mt-8">5. Data Security & Retention</h2>
        <p>We implement industry-standard security measures to protect your data, including encryption and secure servers.</p>
        <p>We retain user data only as long as necessary for our services or as required by law.</p>

        <h2 className="text-2xl font-semibold mt-8">6. Your Privacy Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>✅ Request access to your data.</li>
          <li>✅ Request deletion of your account and data.</li>
          <li>✅ Update or modify your profile information.</li>
        </ul>
        <p>To exercise these rights, email hello@trackinfluence.com.</p>

        <h2 className="text-2xl font-semibold mt-8">7. Changes to This Policy</h2>
        <p>We may update this Privacy Policy periodically. Users will be notified of major changes.</p>

        <h2 className="text-2xl font-semibold mt-8">8. Contact Us</h2>
        <p>For privacy-related questions, contact hello@trackinfluence.com.</p>
      </main>
      <Footer />
    </div>
  );
};

export default Privacy;