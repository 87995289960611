
import { BrightDataIntegration } from "@/components/guides/BrightDataIntegration";

const BrightDataIntegrationPage = () => {
  return (
    <div className="container py-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">BrightData Integration Guide</h1>
      <BrightDataIntegration />
    </div>
  );
};

export default BrightDataIntegrationPage;
