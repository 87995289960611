
import { Lock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface LockedFeatureProps {
  title: string;
  description: string;
  requiredPlan: string;
  className?: string;
}

export const LockedFeature = ({ 
  title, 
  description, 
  requiredPlan, 
  className = "" 
}: LockedFeatureProps) => {
  const navigate = useNavigate();
  
  return (
    <div className={`relative border rounded-lg p-6 bg-gray-50/80 overflow-hidden ${className}`}>
      <div className="absolute inset-0 flex items-center justify-center bg-white/80 backdrop-blur-[1px] z-10">
        <div className="text-center p-6 max-w-xs">
          <div className="bg-gray-100 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
            <Lock className="h-6 w-6 text-gray-500" />
          </div>
          <h3 className="font-semibold text-lg mb-2">{title}</h3>
          <p className="text-sm text-gray-600 mb-4">{description}</p>
          <Button 
            onClick={() => navigate('/pricing')}
            className="bg-orange-500 hover:bg-orange-600"
          >
            Upgrade to {requiredPlan}
          </Button>
        </div>
      </div>
      <div className="blur-sm pointer-events-none">
        {/* Content that will be blurred */}
        <div className="h-32 flex items-center justify-center text-gray-400">
          Feature preview (blurred when locked)
        </div>
      </div>
    </div>
  );
};
