
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Sidebar } from "@/components/Sidebar";
import { 
  FileSearch, 
  CheckCircle2, 
  BarChart2, 
  TrendingUp, 
  Lock, 
  AlertCircle, 
  ChevronUp, 
  ChevronDown,
  FileDown, 
  FileText 
} from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { LockedFeature } from "@/components/dashboard/LockedFeature";
import { UpgradePrompt } from "@/components/dashboard/UpgradePrompt";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu";

export const ProfileAudit = () => {
  const [linkedinUrl, setLinkedinUrl] = useState("https://linkedin.com/in/johndoe");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [reportGenerated, setReportGenerated] = useState(true); // Set to true for demo
  const { toast } = useToast();
  const { userPlan, canAccessIndividual, canAccessPro } = usePlanFeatures();

  // Test data for demo
  const [profileScore] = useState(86);
  const [bestPostingTime] = useState("Tuesday 10:00 AM - 12:00 PM");
  const [profileCompleteness] = useState(92);
  const [followerCount] = useState(3865);
  const [followerGrowth] = useState(12.8);
  const [engagementRate] = useState(4.7);
  const [postFrequency] = useState(2.3);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!linkedinUrl) {
      toast({
        title: "Error",
        description: "Please enter a LinkedIn URL",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    setProgress(0);

    const progressInterval = setInterval(() => {
      setProgress(prev => Math.min(prev + 2, 90));
    }, 100);

    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      clearInterval(progressInterval);
      setProgress(100);

      toast({
        title: "Success",
        description: "Profile audit completed successfully",
      });

      setReportGenerated(true);
    } catch (error) {
      console.error("Error generating report:", error);
      toast({
        title: "Error",
        description: "Failed to generate report. Please try again.",
        variant: "destructive",
      });
    } finally {
      clearInterval(progressInterval);
      setIsLoading(false);
    }
  };

  const handleExport = (format: string) => {
    toast({
      title: `Export ${format.toUpperCase()} Started`,
      description: `Your ${format.toUpperCase()} report is being generated and will download shortly.`,
    });
    
    // Simulate download delay
    setTimeout(() => {
      toast({
        title: `${format.toUpperCase()} Report Ready`,
        description: `Your ${format.toUpperCase()} report has been downloaded successfully.`,
      });
    }, 2000);
  };

  return (
    <div className="flex flex-1">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="max-w-5xl mx-auto space-y-8">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold tracking-tight">Profile Audit</h1>
            <p className="text-muted-foreground">
              Analyze any LinkedIn profile to get detailed insights and recommendations
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex gap-4">
              <Input
                type="url"
                placeholder="Enter LinkedIn profile URL"
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
                className="flex-1"
                disabled={isLoading}
              />
              <Button type="submit" disabled={isLoading}>
                Analyze Profile
              </Button>
            </div>

            {isLoading && (
              <div className="space-y-2">
                <Progress value={progress} className="h-2" />
                <p className="text-sm text-muted-foreground">
                  Analyzing profile... {progress}%
                </p>
              </div>
            )}
          </form>

          {reportGenerated && (
            <div className="space-y-8 mt-8">
              <div className="flex flex-wrap gap-4 items-center justify-between">
                <h2 className="text-xl font-semibold">Audit Results for John Doe</h2>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="flex gap-2 items-center">
                      <FileDown className="h-4 w-4" />
                      <span>Export Report</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-40">
                    <DropdownMenuItem onClick={() => handleExport('pdf')} className="cursor-pointer">
                      <FileText className="h-4 w-4 mr-2" />
                      <span>PDF Format</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleExport('csv')} className="cursor-pointer">
                      <FileText className="h-4 w-4 mr-2" />
                      <span>CSV Format</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              
              {/* Overall Score */}
              <Card>
                <CardHeader>
                  <CardTitle>Overall Score</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-8">
                    <div className="flex items-center justify-center">
                      <div className="w-36 h-36 rounded-full border-8 border-orange-500 flex items-center justify-center">
                        <span className="text-5xl font-bold">{profileScore}</span>
                      </div>
                    </div>
                    <div className="flex-1 space-y-4">
                      <p>Your LinkedIn profile is <span className="font-semibold text-green-600">well-optimized</span> but there are some opportunities for improvement.</p>
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm text-gray-500">Followers</p>
                          <p className="text-xl font-semibold">{followerCount}</p>
                          <div className="flex items-center text-green-600 text-sm mt-1">
                            <ChevronUp className="h-3 w-3 mr-1" />
                            {followerGrowth}%
                          </div>
                        </div>
                        <div className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm text-gray-500">Engagement</p>
                          <p className="text-xl font-semibold">{engagementRate}%</p>
                          <div className="flex items-center text-green-600 text-sm mt-1">
                            <ChevronUp className="h-3 w-3 mr-1" />
                            2.1%
                          </div>
                        </div>
                        <div className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm text-gray-500">Post Frequency</p>
                          <p className="text-xl font-semibold">{postFrequency}/wk</p>
                          <div className="flex items-center text-amber-600 text-sm mt-1">
                            <ChevronDown className="h-3 w-3 mr-1" />
                            0.8/wk
                          </div>
                        </div>
                        <div className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm text-gray-500">Profile Score</p>
                          <p className="text-xl font-semibold">{profileCompleteness}%</p>
                          <div className="flex items-center text-green-600 text-sm mt-1">
                            <ChevronUp className="h-3 w-3 mr-1" />
                            5%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
              
              {/* Content Analysis */}
              <div>
                <h3 className="text-lg font-semibold mb-4">Content Analysis</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-base">Best Posting Times</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-center p-4">
                        <div className="bg-green-100 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-3">
                          <CheckCircle2 className="w-6 h-6 text-green-600" />
                        </div>
                        <p className="font-medium">{bestPostingTime}</p>
                        <p className="text-sm text-gray-600 mt-2">
                          Posts published during this time receive 42% more engagement
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                  
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-base">Hashtag Performance</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-3">
                        <div className="flex justify-between items-center">
                          <span>#leadership</span>
                          <span className="font-medium text-green-600">+24% reach</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>#marketing</span>
                          <span className="font-medium text-green-600">+18% reach</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>#technology</span>
                          <span className="font-medium text-amber-600">+5% reach</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>#innovation</span>
                          <span className="font-medium text-green-600">+32% reach</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>#startups</span>
                          <span className="font-medium text-green-600">+15% reach</span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              {/* Post Type Analysis */}
              <div>
                <h3 className="text-lg font-semibold mb-4">Post Type Analysis</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">Text Posts</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-center p-3">
                        <div className="font-bold text-2xl text-blue-600">+12%</div>
                        <p className="text-sm text-gray-600 mt-1">Above industry average</p>
                        <div className="w-full bg-gray-200 h-2 mt-3 rounded-full">
                          <div className="bg-blue-600 h-2 rounded-full" style={{ width: '72%' }}></div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">Image Posts</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-center p-3">
                        <div className="font-bold text-2xl text-green-600">+28%</div>
                        <p className="text-sm text-gray-600 mt-1">Above industry average</p>
                        <div className="w-full bg-gray-200 h-2 mt-3 rounded-full">
                          <div className="bg-green-600 h-2 rounded-full" style={{ width: '86%' }}></div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">Video Posts</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-center p-3">
                        <div className="font-bold text-2xl text-amber-600">-8%</div>
                        <p className="text-sm text-gray-600 mt-1">Below industry average</p>
                        <div className="w-full bg-gray-200 h-2 mt-3 rounded-full">
                          <div className="bg-amber-600 h-2 rounded-full" style={{ width: '45%' }}></div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              {/* Profile Optimization */}
              <div>
                <h3 className="text-lg font-semibold mb-4">Profile Optimization</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-base">Profile Completeness</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-4">
                        <div>
                          <div className="flex justify-between mb-1">
                            <span className="text-sm font-medium">{profileCompleteness}% Complete</span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div 
                              className="bg-green-600 h-2.5 rounded-full" 
                              style={{ width: `${profileCompleteness}%` }}
                            ></div>
                          </div>
                        </div>
                        
                        <div className="space-y-2">
                          <div className="flex items-start gap-2">
                            <CheckCircle2 className="h-5 w-5 text-green-600 mt-0.5" />
                            <span>Your profile photo and banner are well optimized</span>
                          </div>
                          <div className="flex items-start gap-2">
                            <CheckCircle2 className="h-5 w-5 text-green-600 mt-0.5" />
                            <span>Your headline is compelling and keyword-rich</span>
                          </div>
                          <div className="flex items-start gap-2">
                            <CheckCircle2 className="h-5 w-5 text-green-600 mt-0.5" />
                            <span>Your experience section is detailed with measurable achievements</span>
                          </div>
                          <div className="flex items-start gap-2">
                            <AlertCircle className="h-5 w-5 text-amber-600 mt-0.5" />
                            <span>Add more media to your featured section</span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-base">Competitor Benchmarking</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-3">
                        <div className="flex justify-between items-center">
                          <span>Posting Frequency</span>
                          <span className="font-medium text-amber-600">-20% vs competitors</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>Engagement Rate</span>
                          <span className="font-medium text-green-600">+12% vs competitors</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>Profile Optimization</span>
                          <span className="font-medium text-green-600">+8% vs competitors</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>Content Quality</span>
                          <span className="font-medium text-green-600">+15% vs competitors</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>Network Growth Rate</span>
                          <span className="font-medium text-green-600">+22% vs competitors</span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              {/* Recommendations */}
              <div>
                <h3 className="text-lg font-semibold mb-4">Top Recommendations</h3>
                <Card>
                  <CardContent className="p-6">
                    <div className="space-y-4">
                      <div className="p-4 border rounded-md">
                        <h3 className="font-medium flex items-center">
                          <BarChart2 className="h-5 w-5 mr-2 text-orange-500" />
                          Increase Video Content
                        </h3>
                        <p className="text-sm text-gray-600 mt-2">
                          Your video content performance is below average. Consider creating more video posts to boost engagement.
                          Video posts receive 38% more engagement than text-only posts in your industry.
                        </p>
                      </div>
                      <div className="p-4 border rounded-md">
                        <h3 className="font-medium flex items-center">
                          <TrendingUp className="h-5 w-5 mr-2 text-orange-500" />
                          Optimize Posting Schedule
                        </h3>
                        <p className="text-sm text-gray-600 mt-2">
                          Increase your posting frequency from 2.3 to 3-4 posts per week to match top performers in your industry.
                          Focus on publishing during your optimal engagement windows (Tuesday and Thursday mornings).
                        </p>
                      </div>
                      <div className="p-4 border rounded-md">
                        <h3 className="font-medium flex items-center">
                          <FileSearch className="h-5 w-5 mr-2 text-orange-500" />
                          Enhance Profile Featured Section
                        </h3>
                        <p className="text-sm text-gray-600 mt-2">
                          Add 3-5 media items to your featured section to showcase your best work. Profiles with rich media
                          content receive 45% more profile views and connection requests.
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ProfileAudit;
