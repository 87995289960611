
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BarChart, LineChart } from "lucide-react";
import { ResponsiveContainer, BarChart as RechartsBarChart, LineChart as RechartsLineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Bar } from "recharts";

interface WeeklyPerformanceProps {
  weeklyMetrics: Array<{
    day: string;
    posts: number;
    engagement: number;
  }>;
}

export const WeeklyPerformance = ({ weeklyMetrics }: WeeklyPerformanceProps) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Weekly Performance</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="text-base">Post Activity</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <RechartsBarChart data={weeklyMetrics} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f0" />
                  <XAxis dataKey="day" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                  <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Bar dataKey="posts" fill="#ff9f43" radius={[4, 4, 0, 0]} />
                </RechartsBarChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Total posts this week:</span>
                <span className="font-medium">7</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Best performing day:</span>
                <span className="font-medium">Thursday</span>
              </div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle className="text-base">Engagement Trends</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <RechartsLineChart data={weeklyMetrics} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis dataKey="day" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                  <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Line 
                    type="monotone" 
                    dataKey="engagement" 
                    stroke="#2196f3" 
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 6 }}
                  />
                </RechartsLineChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Average daily engagement:</span>
                <span className="font-medium">196.7</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Week-over-week change:</span>
                <span className="font-medium text-green-600">+18.3%</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
