
import { useState } from "react";
import { Check, ChevronDown, Lock, Plus } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { ProjectLimitDialog } from "../projects/ProjectLimitDialog";

interface ProjectSelectorProps {
  projects: any[];
  activeProject: any | null;
  onProjectSelect: (project: any) => void;
}

export const ProjectSelector = ({
  projects,
  activeProject,
  onProjectSelect,
}: ProjectSelectorProps) => {
  const { canAccessIndividual, canAccessPro } = usePlanFeatures();
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [showLimitDialog, setShowLimitDialog] = useState(false);

  const handleDisabledClick = () => {
    if (!canAccessIndividual) {
      toast({
        title: "Feature locked",
        description: "Upgrade to Individual or higher plan to manage multiple projects.",
        variant: "destructive",
      });
    }
  };

  const handleAddProjectClick = () => {
    setOpen(false);
    if (!canAccessIndividual && projects.length >= 1) {
      // Show limit dialog for free users who already have 1 project
      setShowLimitDialog(true);
    } else {
      // For upgraded users or free users with no projects yet
      document.getElementById("add-project-trigger")?.click();
    }
  };

  // If user doesn't have access to Individual plan, show a disabled selector
  if (!canAccessIndividual) {
    return (
      <>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className="flex items-center justify-between gap-2 min-w-[220px] border border-orange-200 rounded-lg px-4 py-2 cursor-not-allowed"
                onClick={handleDisabledClick}
              >
                <div className="flex items-center">
                  <div className="bg-orange-100 text-orange-600 rounded-full h-6 w-6 flex items-center justify-center text-xs mr-2">
                    {activeProject?.name?.charAt(0).toLowerCase() || "a"}
                  </div>
                  <span className="text-base text-gray-600">{activeProject?.name || "My LinkedIn Profile"}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Lock className="h-4 w-4 text-gray-400" />
                  <ChevronDown className="h-4 w-4 text-gray-400" />
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Upgrade to add and manage multiple projects</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <ProjectLimitDialog 
          open={showLimitDialog} 
          onOpenChange={setShowLimitDialog} 
        />
      </>
    );
  }

  // If no projects are available, don't show the selector
  if (!projects || projects.length === 0) {
    return null;
  }

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant="outline" 
            className="flex items-center justify-between gap-2 min-w-[220px] border-orange-200 hover:border-orange-300 px-4 py-2 h-auto"
          >
            <div className="flex items-center">
              <div className="bg-orange-100 text-orange-600 rounded-full h-6 w-6 flex items-center justify-center text-xs mr-2">
                {activeProject?.name?.charAt(0).toLowerCase() || "a"}
              </div>
              <span className="text-base font-medium">{activeProject?.name || "Select a project"}</span>
            </div>
            <ChevronDown className="h-4 w-4 text-gray-500" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[220px] p-0 bg-white border border-gray-200 shadow-md z-50">
          <div className="max-h-[300px] overflow-y-auto">
            {projects.map((project) => (
              <div 
                key={project.id}
                className={`flex items-center gap-2 p-3 hover:bg-gray-50 cursor-pointer ${
                  activeProject?.id === project.id ? "bg-gray-50" : ""
                }`}
                onClick={() => {
                  onProjectSelect(project);
                  setOpen(false);
                }}
              >
                <div className="bg-orange-100 text-orange-600 rounded-full h-6 w-6 flex items-center justify-center text-xs">
                  {project.name?.charAt(0).toLowerCase() || "a"}
                </div>
                <div className="flex-1">
                  <div className="font-medium text-orange-500">{project.name}</div>
                  {project.url && (
                    <div className="text-xs text-gray-500 truncate">{project.url}</div>
                  )}
                </div>
                {activeProject?.id === project.id && (
                  <Check className="h-4 w-4 text-green-500" />
                )}
              </div>
            ))}
          </div>
          <div className="border-t p-2">
            <Button 
              variant="ghost" 
              className="w-full justify-start gap-2 text-gray-600 font-normal"
              onClick={handleAddProjectClick}
            >
              <Plus className="h-4 w-4" />
              Add New Project ({projects.length}/{canAccessPro ? 5 : 1})
            </Button>
          </div>
        </PopoverContent>
      </Popover>
      <ProjectLimitDialog 
        open={showLimitDialog} 
        onOpenChange={setShowLimitDialog} 
      />
    </>
  );
};
