
import { useState } from "react";
import { PricingPlanFeature } from "./PricingPlanFeature";
import { PricingPlanButton } from "./PricingPlanButton";
import { PricingPlanHeader } from "./PricingPlanHeader";
import { LemonSqueezyPaymentDialog } from "./LemonSqueezyPaymentDialog";
import { usePricingPlan } from "./usePricingPlan";

interface PricingPlanProps {
  plan: {
    name: string;
    price: string;
    description?: string;
    features: readonly string[];
    popular?: boolean;
    lifetimePrice: string;
  };
  billingPeriod: "monthly" | "lifetime";
  autoOpenPayment?: boolean;
  onAutoOpenComplete?: () => void;
}

export const PricingPlan = ({ 
  plan, 
  billingPeriod,
  autoOpenPayment,
  onAutoOpenComplete 
}: PricingPlanProps) => {
  const {
    showPaymentDialog,
    setShowPaymentDialog,
    buttonConfig,
    isTeamPlan
  } = usePricingPlan(plan.name, autoOpenPayment, onAutoOpenComplete);

  return (
    <div
      className={`bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 ${
        buttonConfig.text !== "Current Plan" && !isTeamPlan ? "hover:scale-105" : ""
      } border ${
        plan.popular ? "border-[#F97316]" : "border-gray-100"
      } relative animate-fade-up text-center flex flex-col`}
    >
      <PricingPlanHeader 
        name={plan.name}
        price={plan.price}
        description={plan.description}
        popular={plan.popular}
        billingPeriod={billingPeriod}
        lifetimePrice={plan.lifetimePrice}
      />
      
      <ul className="space-y-4 mb-auto text-left">
        {plan.features.map((feature, i) => (
          <PricingPlanFeature key={i} feature={feature} />
        ))}
      </ul>
      
      <div className="mt-8">
        <PricingPlanButton
          text={buttonConfig.text}
          action={buttonConfig.action}
          disabled={buttonConfig.disabled}
          className={buttonConfig.className}
        />
      </div>

      {showPaymentDialog && (
        <LemonSqueezyPaymentDialog
          plan={{
            name: plan.name,
            price: Number(billingPeriod === "lifetime" ? plan.lifetimePrice : plan.price),
            features: [...plan.features],
          }}
          billingPeriod={billingPeriod}
          onSuccess={() => setShowPaymentDialog(false)}
          onClose={() => setShowPaymentDialog(false)}
        />
      )}
    </div>
  );
};
