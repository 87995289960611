
import { useState } from "react";
import { ProjectCard } from "./ProjectCard";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { AddProjectDialog } from "@/components/projects/AddProjectDialog";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { ProjectLimitDialog } from "../projects/ProjectLimitDialog";

interface Profile {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
  isArchived?: boolean;
}

interface Project {
  id: string;
  name: string;
  profiles: Profile[];
}

interface ProjectsGridProps {
  projects: Project[];
  onSelectProject: (project: Project) => void;
  onRenameProject: (id: string, name: string) => void;
  onArchiveProject: (id: string) => void;
  onDeleteProject: (id: string) => void;
}

export const ProjectsGrid = ({
  projects,
  onSelectProject,
  onRenameProject,
  onArchiveProject,
  onDeleteProject,
}: ProjectsGridProps) => {
  const { userPlan, canAccessIndividual, canAccessPro } = usePlanFeatures();
  const [showLimitDialog, setShowLimitDialog] = useState(false);

  // Filter out projects with archived profiles
  const activeProjects = projects.filter(project => 
    project.profiles.some(profile => !profile.isArchived)
  );
  
  // Determine if user can create project based on plan
  const canCreateProject = (() => {
    if (canAccessPro) return true; // Pro plan can have up to 5 projects
    if (canAccessIndividual) return activeProjects.length < 1; // Individual plan can have 1 project
    return activeProjects.length < 1; // Free plan can have 1 project
  })();

  const handleAddProjectClick = () => {
    if (!canAccessIndividual && activeProjects.length >= 1) {
      // Show limit dialog for free users who already have 1 project
      setShowLimitDialog(true);
    }
  };

  return (
    <div className="space-y-6">
      {!canAccessIndividual && activeProjects.length > 0 && (
        <div className="p-4 bg-orange-50 border border-orange-100 rounded-lg mb-6">
          <div className="flex items-start">
            <div className="flex-1">
              <h3 className="font-medium">Free Plan Limitations</h3>
              <p className="text-sm text-gray-600 mt-1">
                You're currently on the Free plan. Upgrade to Individual plan to unlock editing features and full analytics.
              </p>
            </div>
            <Button 
              className="bg-orange-500 hover:bg-orange-600 text-white ml-4"
              onClick={() => window.location.href = '/pricing'}
            >
              Upgrade Now
            </Button>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {activeProjects.map((project) => (
          <ProjectCard
            key={project.id}
            {...project}
            onSelect={() => onSelectProject(project)}
            onRename={onRenameProject}
            onArchive={onArchiveProject}
            onDelete={onDeleteProject}
            canEdit={canAccessIndividual} // Only Individual+ plans can edit projects
          />
        ))}
        {activeProjects.length === 0 && (
          <div className="col-span-full p-8 text-center">
            <h3 className="text-xl font-semibold mb-2">No Projects Yet</h3>
            <p className="text-muted-foreground mb-6">
              Create your first project to start analyzing LinkedIn profiles
            </p>
            <AddProjectDialog>
              <Button className="gap-2">
                <Plus className="h-4 w-4" />
                Add Your First Project
              </Button>
            </AddProjectDialog>
          </div>
        )}
      </div>
      
      {activeProjects.length > 0 && canCreateProject && (
        <div className="flex justify-center mt-6">
          <AddProjectDialog>
            <Button variant="outline" className="gap-2">
              <Plus className="h-4 w-4" />
              Add New Project
            </Button>
          </AddProjectDialog>
        </div>
      )}
      
      {activeProjects.length > 0 && !canCreateProject && canAccessIndividual && !canAccessPro && (
        <div className="text-center mt-6">
          <p className="text-sm text-muted-foreground mb-3">
            You've reached the maximum number of projects for your plan.
          </p>
          <Button 
            className="bg-orange-500 hover:bg-orange-600 text-white"
            onClick={() => window.location.href = '/pricing'}
          >
            Upgrade to Pro
          </Button>
        </div>
      )}
      
      {activeProjects.length > 0 && !canCreateProject && !canAccessIndividual && (
        <div className="flex justify-center mt-6">
          <Button 
            variant="outline" 
            className="gap-2"
            onClick={handleAddProjectClick}
          >
            <Plus className="h-4 w-4" />
            Add New Project
          </Button>
        </div>
      )}
      
      <ProjectLimitDialog 
        open={showLimitDialog} 
        onOpenChange={setShowLimitDialog} 
      />
    </div>
  );
};
