
import { Button } from "@/components/ui/button";

interface PricingPlanButtonProps {
  text: string;
  action: (() => void) | null;
  disabled: boolean;
  className: string;
}

export const PricingPlanButton = ({
  text,
  action,
  disabled,
  className,
}: PricingPlanButtonProps) => {
  return (
    <Button
      className={`w-full text-white font-semibold ${className}`}
      onClick={action}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
