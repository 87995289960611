
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

interface BillingPeriodSelectorProps {
  billingPeriod: "monthly" | "lifetime";
  onBillingPeriodChange: (value: "monthly" | "lifetime") => void;
}

export const BillingPeriodSelector = ({
  billingPeriod,
  onBillingPeriodChange,
}: BillingPeriodSelectorProps) => {
  return (
    <div className="bg-gray-50 p-2 rounded-lg shadow-sm">
      <RadioGroup
        defaultValue="monthly"
        className="flex items-center space-x-1"
        onValueChange={(value) => onBillingPeriodChange(value as "monthly" | "lifetime")}
      >
        <div className="flex items-center space-x-2 px-4 py-2 relative">
          <RadioGroupItem value="monthly" id="monthly" className="hidden" />
          <label 
            htmlFor="monthly" 
            className={`text-sm font-medium cursor-pointer flex flex-col items-center ${
              billingPeriod === "monthly" ? "text-linkedin-primary" : "text-gray-600"
            }`}
          >
            Monthly
          </label>
        </div>

        <div 
          className={`w-12 h-6 rounded-full p-1 cursor-pointer transition-colors duration-200 ${
            billingPeriod === "lifetime" ? "bg-linkedin-primary" : "bg-gray-300"
          }`}
          onClick={() => onBillingPeriodChange(billingPeriod === "monthly" ? "lifetime" : "monthly")}
        >
          <div 
            className={`w-4 h-4 bg-white rounded-full shadow-sm transform transition-transform duration-200 ${
              billingPeriod === "lifetime" ? "translate-x-6" : "translate-x-0"
            }`} 
          />
        </div>

        <div className="flex items-center space-x-2 px-4 py-2">
          <RadioGroupItem value="lifetime" id="lifetime" className="hidden" />
          <label 
            htmlFor="lifetime" 
            className={`text-sm font-medium cursor-pointer flex flex-col items-center ${
              billingPeriod === "lifetime" ? "text-linkedin-primary" : "text-gray-600"
            }`}
          >
            Lifetime
            <span className="text-xs text-linkedin-primary font-medium">Save 90%</span>
          </label>
        </div>
      </RadioGroup>
    </div>
  );
};
