import { Footer } from "@/components/Footer";

const Terms = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1 container mx-auto px-4 py-8 prose prose-slate max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">📜 Terms of Service – TrackInfluence</h1>
        
        <p className="text-gray-600 mb-8">Effective Date: March 14, 2024</p>

        <p>Welcome to TrackInfluence.com ("TrackInfluence," "we," "us," or "our"). These Terms of Service ("Terms") govern your access to and use of our website, application, and services (collectively, the "Services").</p>

        <p>By using TrackInfluence, you agree to these Terms. If you do not agree, please do not use our Services.</p>

        <h2 className="text-2xl font-semibold mt-8">1. Overview of Services</h2>
        <p>TrackInfluence provides analytics and insights based on publicly available LinkedIn data. Our platform helps users track their profile performance, engagement trends, and audience growth.</p>
        <p>You acknowledge that TrackInfluence is not affiliated with LinkedIn and does not store or scrape private LinkedIn data.</p>

        <h2 className="text-2xl font-semibold mt-8">2. User Accounts & Registration</h2>
        <ul>
          <li>To use our Services, you must register an account via Clerk.com authentication.</li>
          <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
          <li>You must be at least 18 years old to use TrackInfluence.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">3. Subscription & Payments</h2>
        <ul>
          <li>Free Plan: Basic profile analysis is available for free.</li>
          <li>Paid Plans: Users can upgrade to Individual, Pro, or Team plans with a 7-day free trial before billing starts.</li>
          <li>Billing & Auto-Renewal: Subscriptions are auto-renewed unless canceled before the next billing cycle.</li>
          <li>Cancellation: Users can cancel their subscription anytime from their account settings.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">4. User Responsibilities & Acceptable Use</h2>
        <p>By using TrackInfluence, you agree NOT to:</p>
        <ul>
          <li>✅ Use TrackInfluence for illegal or unauthorized purposes.</li>
          <li>✅ Attempt to scrape, hack, or misuse LinkedIn data.</li>
          <li>✅ Share your account credentials with others.</li>
          <li>✅ Use TrackInfluence to spam, harass, or violate the rights of others.</li>
        </ul>
        <p>We reserve the right to suspend or terminate accounts that violate these terms.</p>

        <h2 className="text-2xl font-semibold mt-8">5. Data Collection & Privacy</h2>
        <p>TrackInfluence collects publicly available LinkedIn data and user-submitted information. We do not store private LinkedIn data or access user messages.</p>

        <h2 className="text-2xl font-semibold mt-8">6. Disclaimers & Limitations of Liability</h2>
        <ul>
          <li>✅ TrackInfluence is provided "as is" and "as available" without warranties of any kind.</li>
          <li>✅ We do not guarantee that LinkedIn data will always be available or accurate.</li>
          <li>✅ We are not liable for any loss or damages caused by service interruptions or LinkedIn policy changes.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">7. Modifications to the Terms</h2>
        <p>We reserve the right to update these Terms at any time. Users will be notified of significant changes. Continued use after an update constitutes acceptance of the new Terms.</p>

        <h2 className="text-2xl font-semibold mt-8">8. Contact Information</h2>
        <p>For any questions about these Terms, contact us at hello@trackinfluence.com.</p>
      </main>
      <Footer />
    </div>
  );
};

export default Terms;