
export const pricingPlans = [
  {
    name: "Individual",
    price: "10",
    lifetimePrice: "100",
    description: "For solo professionals",
    features: [
      "1 Profile",
      "1 User",
      "Content Reach Analysis",
      "Export Reports",
      "Target Keywords (up to 3)",
      "Competitor Tracking (up to 3)",
      "AI-Driven Insights",
      "Daily Rank Tracking",
      "Email Support",
    ],
  },
  {
    name: "Pro",
    description: "For Growing Brands 🚀",
    price: "18",
    lifetimePrice: "180",
    popular: true,
    features: [
      "All Individual Features",
      "Up to 7 Profiles",
      "2 Users",
      "Target Keywords (up to 70)",
      "Competitor Tracking (up to 35)",
      "Advanced AI insights",
      "Advanced actionable Insights",
      "Priority Email Support",
    ],
  },
  {
    name: "Team",
    description: "For agencies & marketing teams",
    price: "35",
    lifetimePrice: "350",
    features: [
      "All Pro Features",
      "Manage Up to 20 Profiles",
      "5 Users",
      "Aggregated Team Metrics",
      "Branded Reports",
      "Comment & Sentiment Analysis",
      "Priority Support",
      "Dedicated Account Manager",
    ],
  },
] as const;

export const getPlanByName = (planName?: string) => {
  if (!planName) return pricingPlans[0]; // Default to Individual plan
  return pricingPlans.find(plan => plan.name.toLowerCase() === planName.toLowerCase()) || pricingPlans[0];
};

export const getPlanFeatures = (planName?: string) => {
  const plan = getPlanByName(planName);
  return plan.features;
};

export const isPlanOrHigher = (userPlan: string, requiredPlan: string) => {
  const planRank = {
    'free': 0,
    'individual': 1,
    'pro': 2,
    'team': 3
  };
  
  const userRank = planRank[userPlan.toLowerCase() as keyof typeof planRank] || 0;
  const requiredRank = planRank[requiredPlan.toLowerCase() as keyof typeof planRank] || 0;
  
  return userRank >= requiredRank;
};
