
import { useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { LemonSqueezyWindow } from "@/types/lemonsqueezy";
import { LEMON_PRODUCTS } from "@/utils/lemonsqueezy";

export const useLemonSqueezyCheckout = ({
  plan,
  billingPeriod,
  onClose,
  isCheckoutSuccess,
}: {
  plan: {
    name: string;
    price: number;
    features: string[];
  };
  billingPeriod: "monthly" | "lifetime";
  onClose: () => void;
  isCheckoutSuccess: boolean;
}) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const { user } = useUser();
  const { toast } = useToast();

  useEffect(() => {
    let scriptElement: HTMLScriptElement | null = null;
    let styleElement: HTMLStyleElement | null = null;
    let checkoutInitialized = false;

    const initializeCheckout = async () => {
      if (isCheckoutSuccess || checkoutInitialized) return;

      try {
        // Create script element
        scriptElement = document.createElement('script');
        scriptElement.src = "https://app.lemonsqueezy.com/js/lemon.js";
        scriptElement.async = true;
        
        // Wait for script to load
        await new Promise<void>((resolve, reject) => {
          if (!scriptElement) return reject(new Error('Script element not created'));
          scriptElement.onload = () => resolve();
          scriptElement.onerror = () => reject(new Error('Failed to load LemonSqueezy script'));
          document.body.appendChild(scriptElement);
        });

        const lemonWindow = window as unknown as LemonSqueezyWindow;
        lemonWindow.createLemonSqueezy();

        const planKey = plan.name.toLowerCase();
        const product = LEMON_PRODUCTS[planKey];
        
        if (!product) {
          throw new Error("Invalid plan selected");
        }

        const variantId = billingPeriod === "monthly" ? product.monthlyId : product.lifetimeId;

        // Check URL for discount parameter
        const urlParams = new URLSearchParams(window.location.search);
        const discountCode = urlParams.get('discount') || '';

        // In development, use mock data
        if (isDevelopment) {
          console.log('Development mode: Using mock checkout URL');
          lemonWindow.LemonSqueezy.Setup({});
          lemonWindow.LemonSqueezy.Url.Open('https://demo.lemonsqueezy.com/checkout');
          return;
        }

        // Ensure user is logged in for production
        if (!user?.id) {
          throw new Error('User must be logged in to checkout');
        }

        console.log('Creating checkout session with:', {
          variantId,
          storeId: product.storeId,
          userId: user.id,
          email: user.emailAddresses?.[0]?.emailAddress,
          discountCode
        });

        // For production, create real checkout
        const { data: checkoutData, error: checkoutError } = await supabase.functions.invoke('create-checkout', {
          body: {
            variantId,
            storeId: product.storeId,
            userId: user.id,
            email: user.emailAddresses?.[0]?.emailAddress,
            name: user.fullName,
            discountCode
          }
        });

        if (checkoutError) {
          console.error('Checkout creation error:', checkoutError);
          throw new Error('Failed to create checkout session: ' + checkoutError.message);
        }

        if (!checkoutData?.url) {
          console.error('Invalid checkout response:', checkoutData);
          throw new Error('Invalid checkout response from server');
        }

        // Configure LemonSqueezy
        await lemonWindow.LemonSqueezy.Setup({
          checkoutData: {
            email: user.emailAddresses?.[0]?.emailAddress,
            name: user.fullName,
            custom: {
              user_id: user.id
            }
          }
        });

        // Open checkout
        lemonWindow.LemonSqueezy.Url.Open(checkoutData.url);
        checkoutInitialized = true;

        // Multiple refresh calls to ensure proper sizing
        setTimeout(() => {
          if (lemonWindow.LemonSqueezy.Refresh) {
            lemonWindow.LemonSqueezy.Refresh();
          }
        }, 100);

        setTimeout(() => {
          if (lemonWindow.LemonSqueezy.Refresh) {
            lemonWindow.LemonSqueezy.Refresh();
          }
        }, 500);

      } catch (error) {
        console.error('[LemonSqueezyPaymentDialog] Error:', error);
        toast({
          title: "Payment Error",
          description: error instanceof Error ? error.message : "There was an error initiating the payment. Please try again.",
          variant: "destructive",
        });
        onClose();
      }
    };

    initializeCheckout();

    // Cleanup function
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
      if (styleElement && document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
      checkoutInitialized = false;
    };
  }, [plan, billingPeriod, user, onClose, toast, isCheckoutSuccess, isDevelopment]);
};
