
import { Star } from "lucide-react";

interface PricingPlanHeaderProps {
  name: string;
  price: string;
  description?: string;
  popular?: boolean;
  billingPeriod: "monthly" | "lifetime";
  lifetimePrice: string;
}

export const PricingPlanHeader = ({
  name,
  price,
  description,
  popular,
  billingPeriod,
  lifetimePrice,
}: PricingPlanHeaderProps) => {
  return (
    <>
      {popular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#F97316] text-white px-4 py-1 rounded-full text-sm font-semibold flex items-center gap-1">
          <Star className="w-4 h-4" /> Best Value
        </div>
      )}
      <h3 className="text-2xl font-bold mb-2">{name}</h3>
      {description && (
        <p className="text-gray-600 mb-4 text-sm">{description}</p>
      )}
      <div className="mb-6">
        <span className="text-4xl font-bold">
          ${billingPeriod === "lifetime" ? lifetimePrice : price}
        </span>
        {billingPeriod === "monthly" && <span className="text-gray-600">/month</span>}
      </div>
    </>
  );
};
