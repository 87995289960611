
import { SignUp } from "@clerk/clerk-react";

const SignUpPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <div className="w-full max-w-md">
        <div className="clerk-container">
          <SignUp
            afterSignUpUrl="/dashboard"
            appearance={{
              elements: {
                formButtonPrimary: 'bg-linkedin-primary hover:bg-linkedin-dark',
                card: 'rounded-lg shadow-md',
                headerTitle: 'text-2xl font-bold text-gray-900',
                headerSubtitle: 'text-gray-600',
                socialButtonsBlockButton: 'border border-gray-300 hover:bg-gray-50',
                formFieldInput: 'rounded border-gray-300 focus:border-linkedin-primary focus:ring-linkedin-primary',
                footerActionLink: 'text-linkedin-primary hover:text-linkedin-dark'
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
