
interface LemonSqueezyProduct {
  storeId: string;
  monthlyId: string;
  lifetimeId: string;
}

export const STORE_ID = "151969";

export const LEMON_PRODUCTS: Record<string, LemonSqueezyProduct> = {
  individual: {
    storeId: STORE_ID,
    monthlyId: "688138",
    lifetimeId: "688139"
  },
  pro: {
    storeId: STORE_ID,
    monthlyId: "688140",
    lifetimeId: "688141"
  },
  team: {
    storeId: STORE_ID,
    monthlyId: "688142",
    lifetimeId: "688143"
  }
};
