
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "@/hooks/use-toast";

const CallbackPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn, isLoaded } = useUser();
  const { toast } = useToast();
  const [hasAttemptedRedirect, setHasAttemptedRedirect] = useState(false);

  useEffect(() => {
    // If we've already tried to redirect and Clerk is still loading after 5 seconds,
    // we'll just redirect anyway to prevent the user from being stuck
    const timeoutId = setTimeout(() => {
      if (!isLoaded && !hasAttemptedRedirect) {
        console.log("CallbackPage: Auth state loading timeout, redirecting anyway");
        handleRedirect();
      }
    }, 5000);
    
    return () => clearTimeout(timeoutId);
  }, [isLoaded, hasAttemptedRedirect]);

  useEffect(() => {
    if (!isLoaded) return;

    console.log("CallbackPage: Auth state loaded", { isSignedIn });
    handleRedirect();
  }, [isSignedIn, isLoaded]);

  const handleRedirect = () => {
    setHasAttemptedRedirect(true);
    
    const searchParams = new URLSearchParams(location.search);
    const planParam = searchParams.get('plan');
    const billingParam = searchParams.get('billing');

    let state = {};
    
    if (planParam) {
      try {
        console.log("CallbackPage: Found plan parameter", planParam);
        const decodedPlan = JSON.parse(decodeURIComponent(planParam));
        state = {
          ...state,
          selectedPlan: decodedPlan,
          openPaymentDialog: true // Flag to open payment dialog
        };
      } catch (e) {
        console.error('Error parsing plan data:', e);
      }
    }

    if (billingParam) {
      state = {
        ...state,
        billingPeriod: billingParam
      };
    }

    if (isSignedIn) {
      // Check if coming from a standard sign-in (no plan parameter)
      if (!planParam) {
        console.log("CallbackPage: Standard sign-in detected, redirecting to dashboard");
        navigate("/dashboard", { replace: true });
        return;
      }
      
      console.log("CallbackPage: User is signed in, redirecting to pricing with state", state);
      // After sign-in, redirect back to pricing with the plan data
      navigate("/pricing", { 
        state,
        replace: true 
      });
      
      toast({
        title: "Successfully signed in!",
        description: "Please complete your subscription to continue.",
      });
    } else {
      // If somehow not signed in, redirect to pricing
      console.log("CallbackPage: User is not signed in, redirecting to pricing");
      navigate("/pricing", { replace: true });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <h2 className="text-2xl font-semibold text-gray-900">Setting up your account...</h2>
        <p className="mt-2 text-gray-600">Please wait while we redirect you.</p>
      </div>
    </div>
  );
};

export default CallbackPage;
