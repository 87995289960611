
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const AudienceInsights = () => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Audience Insights</h2>
      <Card>
        <CardHeader>
          <CardTitle className="text-base">Industry Breakdown</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-blue-500 rounded-sm mr-2"></div>
                <span>Technology</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-blue-500 rounded-full" style={{width: '42%'}}></div>
                </div>
                <span className="text-sm">42%</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-sm mr-2"></div>
                <span>Marketing</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-green-500 rounded-full" style={{width: '28%'}}></div>
                </div>
                <span className="text-sm">28%</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-orange-500 rounded-sm mr-2"></div>
                <span>Finance</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-orange-500 rounded-full" style={{width: '15%'}}></div>
                </div>
                <span className="text-sm">15%</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-purple-500 rounded-sm mr-2"></div>
                <span>Education</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-purple-500 rounded-full" style={{width: '10%'}}></div>
                </div>
                <span className="text-sm">10%</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-gray-500 rounded-sm mr-2"></div>
                <span>Other</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-gray-500 rounded-full" style={{width: '5%'}}></div>
                </div>
                <span className="text-sm">5%</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
