import { Button } from "@/components/ui/button";
import { ArrowLeft, Download } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

interface PostsHeaderProps {
  onBack?: () => void;
}

export const PostsHeader = ({ onBack }: PostsHeaderProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('/');
    }
    toast({
      title: "Navigation",
      description: "Returning to report...",
    });
  };

  return (
    <div className="flex items-center justify-between">
      <Button
        variant="outline"
        onClick={handleBack}
        className="flex items-center gap-2 hover:bg-gray-100 transition-colors"
      >
        <ArrowLeft className="w-4 h-4" />
        Back to Report
      </Button>
    </div>
  );
};