
import { Sidebar } from "@/components/Sidebar";
import { DashboardHeader } from "@/components/dashboard/DashboardHeader";
import { DashboardContent } from "@/components/dashboard/DashboardContent";
import { ProjectsView } from "@/components/dashboard/ProjectsView";
import { DeleteProjectDialog } from "@/components/dashboard/DeleteProjectDialog";
import { useDashboardProjects } from "@/hooks/useDashboardProjects";
import { useLinkedInData } from "@/hooks/useLinkedInData";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

const Dashboard = () => {
  const { 
    projects, 
    activeProject, 
    viewMode,
    selectedProject,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    handleDeleteProject,
    handleArchiveProject,
    handleProjectSelect,
    handleBackToProjects,
    handleRenameActiveProject,
    handleDeleteActiveProject,
    openDeleteDialog
  } = useDashboardProjects();
  
  const { linkedInData, isLoading } = useLinkedInData(activeProject, viewMode);
  const { userPlan, canAccessIndividual, canAccessPro, maxProjects } = usePlanFeatures();

  return (
    <div className="flex flex-1">
      <Sidebar />
      <main className="flex-1 overflow-auto">
        <div className="container py-6 space-y-8">
          {viewMode === "detail" ? (
            <DashboardHeader
              projectName={activeProject?.name || "Dashboard"}
              projects={projects}
              activeProject={activeProject}
              onProjectSelect={handleProjectSelect}
              onBackToProjects={handleBackToProjects}
              onRenameProject={handleRenameActiveProject}
              onDeleteProject={handleDeleteActiveProject}
            />
          ) : (
            <DashboardHeader 
              projectName="Your Projects" 
            />
          )}
          
          {viewMode === "grid" ? (
            <ProjectsView 
              projects={projects}
              onSelectProject={handleProjectSelect}
              onRenameProject={(id, name) => openDeleteDialog(id, name)}
              onArchiveProject={handleArchiveProject}
              onDeleteProject={(id) => {
                const project = projects.find(p => p.id === id);
                if (project) {
                  openDeleteDialog(id, project.name);
                }
              }}
              canAccessPro={canAccessPro}
              canAccessIndividual={canAccessIndividual}
              maxProjects={maxProjects}
            />
          ) : (
            activeProject && <DashboardContent 
              projectName={activeProject.name} 
              profileData={activeProject.profiles.find(p => p.isActive)} 
              linkedInData={linkedInData}
              isLoading={isLoading}
            />
          )}
        </div>
      </main>

      <DeleteProjectDialog
        isOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        selectedProject={selectedProject}
        onConfirmDelete={handleDeleteProject}
      />
    </div>
  );
};

export default Dashboard;
