
import { Sidebar } from "@/components/Sidebar";
import { BrightDataGuide } from "@/components/brightdata/BrightDataGuide";
import { useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";

export const BrightDataGuidePage = () => {
  const { user, isLoaded } = useUser();
  
  // Debug user email information
  useEffect(() => {
    if (isLoaded && user) {
      console.log("Current user emails:", user.emailAddresses.map(email => email.emailAddress));
    }
  }, [isLoaded, user]);
  
  // Only allow specific admin emails
  const allowedEmails = ['gal.mor.data@gmail.com', 'gal@guruz.zone'];
  
  // Check if any of the user's email addresses match the allowed list
  const isAdmin = isLoaded && user && user.emailAddresses.some(email => 
    allowedEmails.includes(email.emailAddress)
  );
  
  // Debug access information
  useEffect(() => {
    if (isLoaded) {
      console.log("User loaded, admin access:", isAdmin);
      console.log("Allowed emails:", allowedEmails);
    }
  }, [isLoaded, isAdmin, allowedEmails]);
  
  // Show loading state while Clerk is loading
  if (!isLoaded) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }
  
  // Redirect if not an admin
  if (!isAdmin) {
    console.log("Access denied, redirecting to dashboard");
    return <Navigate to="/dashboard" replace />;
  }
  
  return (
    <div className="flex flex-1">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="max-w-3xl mx-auto space-y-8">
          <h1 className="text-2xl font-bold">Admin: BrightData API Setup</h1>
          <p className="text-gray-600">
            Add your BrightData API key to enable LinkedIn data retrieval for profile audits across TrackInfluence.
          </p>
          <BrightDataGuide />
        </div>
      </main>
    </div>
  );
};

export default BrightDataGuidePage;
