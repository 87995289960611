
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { Edit, Plus, Lock, FileDown, FileText } from "lucide-react";
import { ProjectSelector } from "./ProjectSelector";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AddProjectDialog } from "@/components/projects/AddProjectDialog";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { ProjectSettingsDialog } from "@/components/projects/ProjectSettingsDialog";

interface DashboardHeaderProps {
  projectName: string;
  projects?: any[];
  activeProject?: any | null;
  onProjectSelect?: (project: any) => void;
  onBackToProjects?: () => void;
  onEditProject?: () => void;
  onRenameProject?: (newName: string) => void;
  onDeleteProject?: () => void;
}

export const DashboardHeader = ({ 
  projectName, 
  projects = [], 
  activeProject = null,
  onProjectSelect,
  onBackToProjects,
  onRenameProject,
  onDeleteProject
}: DashboardHeaderProps) => {
  const { userPlan, canAccessIndividual, canAccessPro } = usePlanFeatures();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  
  const handleEditClick = () => {
    if (!canAccessIndividual) {
      toast({
        title: "Feature locked",
        description: "Upgrade to Individual or higher plan to edit projects.",
        variant: "destructive",
      });
      return;
    }
    
    setIsSettingsOpen(true);
  };

  const handleExport = (format: string) => {
    toast({
      title: `Export ${format.toUpperCase()} Started`,
      description: `Your ${format.toUpperCase()} report is being generated and will download shortly.`,
    });
    
    // Simulate download delay
    setTimeout(() => {
      toast({
        title: `${format.toUpperCase()} Report Ready`,
        description: `Your ${format.toUpperCase()} report has been downloaded successfully.`,
      });
    }, 2000);
  };
  
  return (
    <>
      <div className="flex flex-col gap-4 mb-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            {projects.length > 0 && onProjectSelect && activeProject ? (
              <div className="flex items-center gap-4">
                <ProjectSelector 
                  projects={projects}
                  activeProject={activeProject}
                  onProjectSelect={onProjectSelect}
                />
              </div>
            ) : (
              <h1 className="text-2xl font-bold">{projectName}</h1>
            )}
          </div>
          <div className="flex items-center gap-2">
            {activeProject && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="flex gap-2 items-center">
                    <FileDown className="h-4 w-4" />
                    <span>Export Report</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-40">
                  <DropdownMenuItem onClick={() => handleExport('pdf')} className="cursor-pointer">
                    <FileText className="h-4 w-4 mr-2" />
                    <span>PDF Format</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleExport('csv')} className="cursor-pointer">
                    <FileText className="h-4 w-4 mr-2" />
                    <span>CSV Format</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            
            {userPlan === 'free' && (
              <Button
                size="sm"
                onClick={() => navigate('/pricing')}
                className="bg-gradient-to-r from-orange-400 to-orange-500 hover:from-orange-500 hover:to-orange-600 text-white"
              >
                Upgrade to Individual
              </Button>
            )}
            {userPlan === 'individual' && (
              <Button
                size="sm"
                onClick={() => navigate('/pricing')}
                className="bg-gradient-to-r from-orange-400 to-orange-500 hover:from-orange-500 hover:to-orange-600 text-white"
              >
                Upgrade to Pro
              </Button>
            )}
          </div>
        </div>
        
        {activeProject && (
          <div className="flex items-center gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={handleEditClick}
                      disabled={!canAccessIndividual}
                      className={`flex items-center gap-1 ${!canAccessIndividual ? "cursor-not-allowed" : ""}`}
                    >
                      <Edit className="h-4 w-4" />
                      Edit Project
                      {!canAccessIndividual && <Lock className="h-3 w-3 ml-1" />}
                    </Button>
                  </div>
                </TooltipTrigger>
                {!canAccessIndividual && (
                  <TooltipContent>
                    <p>Upgrade to edit project</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
            
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    {canAccessPro ? (
                      <AddProjectDialog>
                        <Button variant="ghost" size="sm" className="flex items-center gap-1" id="add-project-trigger">
                          <Plus className="h-4 w-4" />
                          Add Project
                        </Button>
                      </AddProjectDialog>
                    ) : (
                      <Button
                        variant="ghost"
                        size="sm"
                        disabled={true}
                        className="flex items-center gap-1 cursor-not-allowed"
                        onClick={() => {
                          toast({
                            title: "Feature locked",
                            description: "Upgrade to Pro plan to add more projects.",
                            variant: "destructive",
                          });
                        }}
                      >
                        <Plus className="h-4 w-4" />
                        Add Project
                        <Lock className="h-3 w-3 ml-1" />
                      </Button>
                    )}
                  </div>
                </TooltipTrigger>
                {!canAccessPro && (
                  <TooltipContent>
                    <p>Upgrade to add project</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>

      {activeProject && isSettingsOpen && onRenameProject && onDeleteProject && (
        <ProjectSettingsDialog
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          projectName={activeProject.name}
          projectUrl={activeProject.profiles?.find((p: any) => p.isActive)?.url}
          onSave={onRenameProject}
          onDelete={onDeleteProject}
        />
      )}
    </>
  );
};
