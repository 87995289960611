
import { useSubscription } from "./useSubscription";
import { isPlanOrHigher } from "@/components/pricing/pricingPlans";

export const usePlanFeatures = () => {
  const { data: subscription, isLoading } = useSubscription();
  // For demo purposes, forcing pro plan
  const userPlan = 'pro';
  
  const canAccess = (requiredPlan: string) => {
    return isPlanOrHigher(userPlan, requiredPlan);
  };
  
  const getUserPlan = () => userPlan;
  
  // Plan feature limits
  const planLimits = {
    free: {
      maxProjects: 1,
      maxKeywords: 0,
      maxCompetitors: 0,
      maxReports: 1,
      maxProfiles: 1,
      canEditProject: false,
      canAddProject: false,
      canViewFollowerGrowth: false,
      canViewEngagementRate: false,
      canViewContentReach: false,
      canViewBestPerformingPost: false,
      canViewRecentPosts: false,
      canViewKeywordAnalysis: false,
      canViewCompetitorAnalysis: false,
      canRunAudit: false,
      canViewContentAnalysis: false,
      canViewProfileOptimization: false,
      dataRetentionDays: 7
    },
    individual: {
      maxProjects: 1,
      maxKeywords: 5,
      maxCompetitors: 3,
      maxReports: 1,
      maxProfiles: 1,
      canEditProject: true,
      canAddProject: false,
      canViewFollowerGrowth: true,
      canViewEngagementRate: true,
      canViewContentReach: true,
      canViewBestPerformingPost: true,
      canViewRecentPosts: true,
      canViewKeywordAnalysis: true,
      canViewCompetitorAnalysis: false,
      canRunAudit: true,
      canViewContentAnalysis: true,
      canViewProfileOptimization: true,
      dataRetentionDays: 30
    },
    pro: {
      maxProjects: 5,
      maxKeywords: 20,
      maxCompetitors: 10,
      maxReports: 5,
      maxProfiles: 5,
      canEditProject: true,
      canAddProject: true,
      canViewFollowerGrowth: true,
      canViewEngagementRate: true,
      canViewContentReach: true,
      canViewBestPerformingPost: true,
      canViewRecentPosts: true,
      canViewKeywordAnalysis: true,
      canViewCompetitorAnalysis: true,
      canRunAudit: true,
      canViewContentAnalysis: true,
      canViewProfileOptimization: true,
      dataRetentionDays: 90
    },
    team: {
      maxProjects: 20,
      maxKeywords: 50,
      maxCompetitors: 25,
      maxReports: 20,
      maxProfiles: 20,
      canEditProject: true,
      canAddProject: true,
      canViewFollowerGrowth: true,
      canViewEngagementRate: true,
      canViewContentReach: true,
      canViewBestPerformingPost: true,
      canViewRecentPosts: true,
      canViewKeywordAnalysis: true,
      canViewCompetitorAnalysis: true,
      canRunAudit: true,
      canViewContentAnalysis: true,
      canViewProfileOptimization: true,
      dataRetentionDays: 365
    }
  };
  
  // Get the current plan limits, defaulting to free plan if not found
  const currentPlanLimits = planLimits[userPlan as keyof typeof planLimits] || planLimits.free;
  
  return {
    userPlan,
    canAccessIndividual: canAccess('individual'),
    canAccessPro: canAccess('pro'),
    canAccessTeam: canAccess('team'),
    isLoading,
    canAccess,
    getUserPlan,
    ...currentPlanLimits
  };
};
