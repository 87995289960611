
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";

interface Post {
  id: number;
  date: string;
  content: string;
  engagement: number;
  likes: number;
  comments: number;
  shares: number;
  type: string;
}

interface RecentPostsListProps {
  recentPosts: Post[];
}

export const RecentPostsList = ({ recentPosts }: RecentPostsListProps) => {
  const formatDate = (dateString: string) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
    } catch (e) {
      console.error("Error formatting date:", e, dateString);
      return dateString;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Recent Posts</h2>
        <Link to="/posts">
          <Button variant="ghost" size="sm" className="flex items-center gap-1">
            <span>All posts</span>
            <ExternalLink className="h-4 w-4" />
          </Button>
        </Link>
      </div>
      <Card>
        <CardContent className="p-0">
          {recentPosts && recentPosts.length > 0 ? (
            <div className="divide-y">
              {recentPosts.map(post => (
                <div key={post.id} className="p-4">
                  <div className="flex justify-between items-start">
                    <div className="space-y-1 flex-1">
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-gray-500">
                          {formatDate(post.date)}
                        </span>
                        <span className="text-xs px-2 py-0.5 bg-gray-100 rounded-full">
                          {post.type}
                        </span>
                      </div>
                      <p className="text-sm line-clamp-2">{post.content}</p>
                    </div>
                    <div className="text-right min-w-24">
                      <div className="text-sm font-medium">{post.engagement}</div>
                      <div className="text-xs text-gray-500">engagements</div>
                    </div>
                  </div>
                  <div className="flex gap-4 mt-2 text-xs text-gray-500">
                    <div>{post.likes} likes</div>
                    <div>{post.comments} comments</div>
                    <div>{post.shares} shares</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-6 text-center text-gray-500">
              <p>No recent posts found</p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
