
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useUser } from "@clerk/clerk-react";

export interface Subscription {
  id: string;
  plan: string;
  status: string;
  max_profiles: number;
  max_reports_per_profile: number;
  can_export: boolean;
  can_export_csv: boolean;
  trial_end?: string;
  lemonsqueezy_subscription_id?: string;
}

const getPlanFromVariantId = (subscriptionId: string | null): string => {
  if (!subscriptionId) {
    console.log('No subscription ID found, defaulting to free plan');
    return 'free';
  }
  
  const variantId = subscriptionId.split('-')[0]; // Extract variant ID from subscription ID
  console.log('Extracted variant ID:', variantId);
  
  switch (variantId) {
    case '688138':
    case '688139':
      console.log('Identified as Individual plan');
      return 'individual';
    case '688140':
    case '688141':
      console.log('Identified as Pro plan');
      return 'pro';
    case '688142':
    case '688143':
      console.log('Identified as Team plan');
      return 'team';
    default:
      console.log('Unknown variant ID, defaulting to free plan');
      return 'free';
  }
};

export const useSubscription = () => {
  const isDevelopment = process.env.NODE_ENV === 'development' || 
    window.location.hostname.includes('lovable.dev') || 
    window.location.hostname.includes('preview--trackfluence-home.lovable.app');

  // Return mock data for development
  if (isDevelopment) {
    return {
      data: {
        id: 'dev-subscription',
        plan: 'pro',
        status: 'active',
        max_profiles: 100,
        max_reports_per_profile: 100,
        can_export: true,
        can_export_csv: true,
      } as Subscription,
      isLoading: false,
      error: null,
    };
  }

  const { user } = useUser();

  return useQuery({
    queryKey: ['subscription', user?.id],
    queryFn: async (): Promise<Subscription | null> => {
      if (!user?.id) {
        console.log('No user ID found');
        return null;
      }

      console.log('Fetching subscription for user:', user.id);
      
      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('clerk_user_id', user.id)
        .limit(1)
        .maybeSingle();

      if (error) {
        console.error('Error fetching subscription:', error);
        return null;
      }

      console.log('Raw subscription data:', data);

      if (data) {
        // Determine plan based on variant ID
        data.plan = getPlanFromVariantId(data.lemonsqueezy_subscription_id);
        console.log('Final subscription data:', data);
      } else {
        console.log('No subscription found for user');
      }

      return data;
    },
    enabled: !!user?.id,
    retry: false,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });
};
