import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

const blogPosts = [
  {
    date: "March 15, 2024",
    title: "10 Ways to Boost Your LinkedIn Engagement",
    description: "Learn the proven strategies to increase your content reach and engagement on...",
    image: "/lovable-uploads/b65eef44-bbe7-47c2-8462-8554e049628c.png",
    slug: "boost-linkedin-engagement"
  },
  {
    date: "March 12, 2024",
    title: "The Power of LinkedIn Analytics",
    description: "Discover how data-driven insights can transform your LinkedIn strategy.",
    image: "/lovable-uploads/b65eef44-bbe7-47c2-8462-8554e049628c.png",
    slug: "linkedin-analytics-power"
  },
  {
    date: "March 10, 2024",
    title: "LinkedIn Growth Hacks for 2024",
    description: "Stay ahead of the curve with these cutting-edge LinkedIn growth strategies.",
    image: "/lovable-uploads/b65eef44-bbe7-47c2-8462-8554e049628c.png",
    slug: "linkedin-growth-hacks-2024"
  }
];

export const BlogPreview = () => {
  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-12">
          <h2 className="text-3xl font-bold text-linkedin-primary">Latest Blog Posts</h2>
          <Link 
            to="/blog" 
            className="text-[#F97316] hover:text-[#EA580C] flex items-center gap-2 font-medium"
          >
            View All Posts
            <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <Link 
              key={index} 
              to={`/blog/${post.slug}`}
              className="group hover:shadow-xl transition-all duration-300 rounded-lg overflow-hidden"
            >
              <div className="bg-white rounded-lg border border-gray-100">
                <img 
                  src={post.image} 
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <span className="text-sm text-[#F97316]">{post.date}</span>
                  <h3 className="text-xl font-semibold mt-2 mb-3 group-hover:text-linkedin-primary transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-gray-600 mb-4">{post.description}</p>
                  <span className="text-[#F97316] font-medium flex items-center gap-2 group-hover:gap-3 transition-all">
                    See more
                    <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};