
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { CheckCircle, AlertTriangle, Info, RefreshCw } from "lucide-react";
import { useState, useEffect } from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface BrightDataApiStatusProps {
  keyValid: boolean | null;
  isTesting: boolean;
  setIsTesting: (isTesting: boolean) => void;
  setKeyValid: (valid: boolean | null) => void;
}

export const BrightDataApiStatus = ({
  keyValid,
  isTesting,
  setIsTesting,
  setKeyValid
}: BrightDataApiStatusProps) => {
  const { toast } = useToast();
  const [testError, setTestError] = useState<string | null>(null);
  const [testDetails, setTestDetails] = useState<string | null>(null);
  
  // Check for API key in localStorage
  useEffect(() => {
    const apiKey = localStorage.getItem("brightdata_api_key")?.trim();
    const fallbackApiKey = localStorage.getItem("brightdata_profiles_api_key")?.trim();
    const hasKey = !!(apiKey || fallbackApiKey);
    
    console.log("API key status check:", { 
      hasKey, 
      keyLength: apiKey ? apiKey.length : (fallbackApiKey ? fallbackApiKey.length : 0)
    });
  }, []);
  
  const testApiKey = async () => {
    setIsTesting(true);
    setKeyValid(null);
    setTestError(null);
    setTestDetails(null);
    
    try {
      console.log("Testing BrightData API key configuration...");
      
      // Get API key from localStorage - CRITICAL: ensure it's properly trimmed
      const apiKey = localStorage.getItem("brightdata_api_key")?.trim();
      const fallbackApiKey = localStorage.getItem("brightdata_profiles_api_key")?.trim();
      const effectiveApiKey = apiKey || fallbackApiKey;
      
      if (!effectiveApiKey) {
        setTestError("No API key found in local storage");
        setKeyValid(false);
        toast({
          title: "API Key Missing",
          description: "No BrightData API key found in local storage",
          variant: "destructive",
        });
        setIsTesting(false);
        return;
      }
      
      console.log("API key available:", !!effectiveApiKey, 
        effectiveApiKey ? `length: ${effectiveApiKey.length}` : '',
        effectiveApiKey ? `first 4 chars: ${effectiveApiKey.substring(0, 4)}` : '',
        effectiveApiKey ? `last 4 chars: ${effectiveApiKey.substring(effectiveApiKey.length - 4)}` : ''
      );
      
      // Test API key using our own brightdata-test edge function
      const testUrl = import.meta.env.VITE_SUPABASE_URL 
        ? `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/brightdata-test`
        : "https://dnwgkklwadvoxfbospfh.supabase.co/functions/v1/brightdata-test";
      
      console.log("Testing API key with edge function:", testUrl);
      
      const response = await fetch(testUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // Using a test LinkedIn profile URL
          url: "https://www.linkedin.com/in/gal-mor/",
          apiKey: effectiveApiKey // Pass the API key from localStorage to the edge function
        })
      });
      
      console.log("Test response received:", {
        status: response.status,
        ok: response.ok,
        statusText: response.statusText
      });
      
      // Always try to parse the response, even if it's not OK
      const responseText = await response.text();
      console.log("Response text:", responseText);
      
      let result;
      try {
        result = JSON.parse(responseText);
      } catch (e) {
        console.error("Failed to parse response as JSON:", e, responseText);
        setTestError(`Failed to parse response: ${responseText.substring(0, 100)}...`);
        setKeyValid(false);
        toast({
          title: "API Response Error",
          description: "Failed to parse API response",
          variant: "destructive",
        });
        setIsTesting(false);
        return;
      }
      
      console.log("Test response body:", result);
      
      if (!response.ok || result.error) {
        let errorMessage = result.error || `API test failed: ${response.status}`;
        setTestError(errorMessage);
        setTestDetails(result.details || null);
        setKeyValid(false);
        
        console.error("API test failed:", errorMessage, result);
        
        toast({
          title: "API Key Validation Failed",
          description: errorMessage,
          variant: "destructive",
        });
        setIsTesting(false);
        return;
      }
      
      if (!result.valid) {
        setTestError("API key validation failed");
        setKeyValid(false);
        toast({
          title: "API Key Validation Failed",
          description: "The API key was not validated successfully",
          variant: "destructive",
        });
        setIsTesting(false);
        return;
      }
      
      // If we reach here, the API call was successful
      setKeyValid(true);
      
      if (result.datasetAccessError) {
        toast({
          title: "API Key Partially Validated",
          description: "The API key is valid but doesn't have access to the LinkedIn dataset.",
          variant: "default",
        });
      } else if (result.warning) {
        toast({
          title: "API Key Partially Validated",
          description: result.warning,
          variant: "default",
        });
      } else {
        toast({
          title: "API Key Validated",
          description: "The BrightData API key is valid and properly configured.",
          variant: "default",
        });
      }
    } catch (error) {
      console.error("Error testing API key:", error);
      setKeyValid(false);
      
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      setTestError(errorMessage);
      
      toast({
        title: "API Key Validation Failed",
        description: "Failed to connect to the API validation service",
        variant: "destructive",
      });
    } finally {
      setIsTesting(false);
    }
  };
  
  return (
    <div className="space-y-4">
      <div className={`flex items-center space-x-2 p-4 rounded-md border ${
        keyValid === true ? "bg-green-50 border-green-200" : 
        keyValid === false ? "bg-red-50 border-red-200" :
        "bg-blue-50 border-blue-200"
      }`}>
        {keyValid === true ? (
          <CheckCircle className="h-5 w-5 text-green-500" />
        ) : keyValid === false ? (
          <AlertTriangle className="h-5 w-5 text-red-500" />
        ) : (
          <Info className="h-5 w-5 text-blue-500" />
        )}
        <div className={`${
          keyValid === true ? "text-green-700" : 
          keyValid === false ? "text-red-700" :
          "text-blue-700"
        }`}>
          <p className="font-medium">
            {keyValid === true ? "BrightData API key is valid and ready to use" :
             keyValid === false ? "API key validation failed" :
             "BrightData API key status"}
          </p>
          {keyValid === true && (
            <p className="text-sm mt-1">
              LinkedIn data should now be accessible across TrackInfluence.
            </p>
          )}
        </div>
      </div>
      
      {testError && (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="space-y-2">
            <p>{testError}</p>
            {testDetails && (
              <details className="text-xs mt-2">
                <summary className="cursor-pointer font-medium">Technical Details</summary>
                <p className="mt-1 whitespace-pre-wrap">{testDetails}</p>
              </details>
            )}
          </AlertDescription>
        </Alert>
      )}
      
      <div className="flex space-x-2">
        <Button 
          variant="outline" 
          disabled={isTesting}
          onClick={testApiKey}
          className="gap-2"
        >
          {isTesting ? (
            <>
              <RefreshCw className="h-4 w-4 animate-spin" />
              Testing...
            </>
          ) : (
            <>
              <RefreshCw className="h-4 w-4" />
              Test API Connection
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
