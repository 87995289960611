
import { Info } from "lucide-react";

export const BrightDataUsageInfo = () => {
  return (
    <div className="mt-6 p-4 bg-blue-50 rounded-md border border-blue-200">
      <div className="flex items-center space-x-2">
        <Info className="h-5 w-5 text-blue-500" />
        <p className="text-blue-700 font-medium">API Usage Optimization</p>
      </div>
      <p className="text-sm text-blue-600 mt-2">
        TrackInfluence implements smart caching to minimize API calls. Data is cached based on your plan:
        Pro: 6 hours, Individual: 12 hours, Free: 24 hours.
      </p>
    </div>
  );
};
