
import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Progress } from "@/components/ui/progress";
import { useToast } from "@/components/ui/use-toast";
import { Plus, Lock, CheckCircle } from "lucide-react";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

interface AddProjectDialogProps {
  children?: React.ReactNode;
  className?: string;
}

export const AddProjectDialog = ({ children, className }: AddProjectDialogProps) => {
  const { toast } = useToast();
  const { userPlan, canAccessIndividual, canAccessPro, maxKeywords, maxCompetitors } = usePlanFeatures();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [projectName, setProjectName] = useState("");
  const [linkedinName, setLinkedinName] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [keywords, setKeywords] = useState<string[]>([""]);
  const [competitors, setCompetitors] = useState<string[]>([""]);
  const [isCreating, setIsCreating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const extractProfileName = (url: string): string => {
    try {
      const urlObj = new URL(url);
      const pathParts = urlObj.pathname.split('/');
      // Get the last non-empty part of the path
      const profileName = pathParts.filter(part => part).pop() || 'Profile';
      return profileName.charAt(0).toUpperCase() + profileName.slice(1);
    } catch {
      return 'Profile';
    }
  };

  // Reset state when dialog opens
  useEffect(() => {
    if (open) {
      setStep(1);
      setProjectName("");
      setLinkedinName("");
      setProfileUrl("");
      setKeywords([""]);
      setCompetitors([""]);
      setIsCreating(false);
      setProgress(0);
      setIsCompleted(false);
    }
  }, [open]);

  const handleNext = () => {
    // Validate current step
    if (step === 1 && !profileUrl) {
      toast({
        title: "Profile URL required",
        description: "Please enter a LinkedIn profile URL",
        variant: "destructive",
      });
      return;
    }
    
    // Move to next step or create project
    if (step === 1) {
      // Extract name from LinkedIn URL for next step
      const name = extractProfileName(profileUrl);
      setLinkedinName(name);
      setProjectName(name);
      setStep(2);
    } else if (step === 2 && !canAccessIndividual) {
      // Free plan users skip to final step
      handleCreateProject();
    } else if ((step === 4) || (step === 2 && !canAccessIndividual)) {
      handleCreateProject();
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleViewProject = () => {
    setOpen(false);
    
    // In a real application, this would navigate to the newly created project
    toast({
      title: "Project Ready",
      description: "Your new project is ready to view.",
    });
  };

  const addKeyword = () => {
    if (keywords.length < maxKeywords) {
      setKeywords([...keywords, ""]);
    } else {
      toast({
        title: `Maximum ${maxKeywords} keywords`,
        description: `Your plan allows up to ${maxKeywords} keywords.`,
        variant: "destructive",
      });
    }
  };

  const updateKeyword = (index: number, value: string) => {
    const newKeywords = [...keywords];
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const addCompetitor = () => {
    if (competitors.length < maxCompetitors) {
      setCompetitors([...competitors, ""]);
    } else {
      toast({
        title: `Maximum ${maxCompetitors} competitors`,
        description: `You can add up to ${maxCompetitors} competitors.`,
        variant: "destructive",
      });
    }
  };

  const updateCompetitor = (index: number, value: string) => {
    const newCompetitors = [...competitors];
    newCompetitors[index] = value;
    setCompetitors(newCompetitors);
  };

  const handleCreateProject = () => {
    setIsCreating(true);
    
    // Simulate processing time and BrightData API call
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          finalizeProjectCreation();
          return 100;
        }
        return prev + 2;
      });
    }, 50);
  };

  const finalizeProjectCreation = () => {
    // Filter empty values
    const filteredKeywords = keywords.filter(k => k.trim());
    const filteredCompetitors = competitors.filter(c => c.trim());
    
    // Create project object
    const newProject = {
      id: Date.now().toString(),
      name: projectName || linkedinName || extractProfileName(profileUrl),
      profiles: [{
        id: `${Date.now()}-0`,
        name: linkedinName || extractProfileName(profileUrl),
        avatarUrl: `https://api.dicebear.com/7.x/avataaars/svg?seed=${Date.now()}`,
        url: profileUrl.trim(),
        isActive: true,
        isProVersion: false,
        isArchived: false
      }],
      keywords: filteredKeywords,
      competitors: filteredCompetitors,
      planLevel: canAccessPro ? 'pro' : canAccessIndividual ? 'individual' : 'free'
    };
    
    const existingProjects = JSON.parse(localStorage.getItem('projects') || '[]');
    const updatedProjects = [...existingProjects, newProject];
    localStorage.setItem('projects', JSON.stringify(updatedProjects));
    
    window.dispatchEvent(new Event('storageUpdated'));
    
    toast({
      title: "Project Created Successfully",
      description: `Created project "${newProject.name}"`,
    });
    
    setTimeout(() => {
      setIsCreating(false);
      setIsCompleted(true);
    }, 1000);
  };

  const renderStepContent = () => {
    if (isCreating) {
      return (
        <div className="space-y-4">
          <div className="text-center">
            <h3 className="font-medium mb-2">Creating Project: {projectName || linkedinName || extractProfileName(profileUrl)}</h3>
            <p className="text-sm text-gray-600">
              Processing profile data using BrightData API...
            </p>
          </div>
          <Progress value={progress} className="h-2" />
          <p className="text-sm text-center text-gray-600">
            {progress < 30 ? "Fetching LinkedIn profile data..." : 
             progress < 60 ? "Analyzing profile metrics..." : 
             progress < 90 ? "Calculating influence score..." :
             "Profile analysis complete"}
          </p>
        </div>
      );
    }

    if (isCompleted) {
      return (
        <div className="space-y-4 text-center">
          <div className="mx-auto bg-green-100 w-16 h-16 rounded-full flex items-center justify-center">
            <CheckCircle className="h-8 w-8 text-green-600" />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Your Profile is Ready!</h3>
            <p className="text-gray-600 mb-6">
              TrackInfluence is now analyzing your LinkedIn profile. Data will be available shortly. 
              It will take a few minutes to generate a complete report.
            </p>
            <Button onClick={handleViewProject} className="w-full">
              View Profile Analysis
            </Button>
          </div>
        </div>
      );
    }

    switch (step) {
      case 1:
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="profileUrl">LinkedIn Profile URL</Label>
                <Input
                  id="profileUrl"
                  placeholder="https://www.linkedin.com/in/username"
                  value={profileUrl}
                  onChange={(e) => setProfileUrl(e.target.value)}
                />
                <p className="text-xs text-gray-500">Enter the LinkedIn profile you want to track</p>
              </div>
              
              <div className="flex justify-between mt-6">
                <Button variant="outline" onClick={() => setOpen(false)}>
                  Close
                </Button>
                <Button onClick={handleNext}>
                  Next
                </Button>
              </div>
            </div>
          </>
        );
        
      case 2:
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="projectName">Project Name</Label>
                <Input
                  id="projectName"
                  placeholder={linkedinName || "My LinkedIn Profile"}
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
                <p className="text-xs text-gray-500">Choose an easy name to identify this profile</p>
              </div>
              
              {!canAccessIndividual && (
                <div className="p-3 bg-orange-50 rounded border border-orange-100 text-sm">
                  <p className="flex items-center text-orange-800">
                    <Lock className="h-4 w-4 mr-2 text-orange-500" />
                    <span>Upgrade to Individual or Pro plan to access more features</span>
                  </p>
                </div>
              )}
              
              <div className="flex justify-between mt-6">
                <Button variant="outline" onClick={handlePrevious}>
                  Back
                </Button>
                <Button onClick={handleNext}>
                  {canAccessIndividual ? "Next" : "Create Project"}
                </Button>
              </div>
            </div>
          </>
        );
        
      case 3:
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label>Target Keywords (Optional)</Label>
                <p className="text-xs text-gray-500 mb-2">
                  Enter relevant keywords to track your performance for specific topics
                  (Up to {maxKeywords} words)
                </p>
                
                {keywords.map((keyword, index) => (
                  <Input
                    key={index}
                    placeholder={`Keyword ${index + 1}`}
                    value={keyword}
                    onChange={(e) => updateKeyword(index, e.target.value)}
                    className="mb-2"
                  />
                ))}
                
                <Button 
                  type="button" 
                  variant="outline" 
                  size="sm" 
                  onClick={addKeyword}
                  disabled={keywords.length >= maxKeywords}
                >
                  + Add Keyword
                </Button>
              </div>
              
              <div className="flex justify-between mt-6">
                <Button variant="outline" onClick={handlePrevious}>
                  Back
                </Button>
                <div className="space-x-2">
                  <Button variant="outline" onClick={handleNext}>
                    Skip
                  </Button>
                  <Button onClick={handleNext}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
        
      case 4:
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label>Add Competitors (Optional)</Label>
                <p className="text-xs text-gray-500 mb-2">
                  Enter the URLs of your main competitors or profiles you admire (Up to {maxCompetitors})
                </p>
                
                {competitors.map((competitor, index) => (
                  <Input
                    key={index}
                    placeholder="https://www.linkedin.com/in/competitor"
                    value={competitor}
                    onChange={(e) => updateCompetitor(index, e.target.value)}
                    className="mb-2"
                  />
                ))}
                
                <Button 
                  type="button" 
                  variant="outline" 
                  size="sm" 
                  onClick={addCompetitor}
                  disabled={competitors.length >= maxCompetitors}
                >
                  + Add Competitor
                </Button>
              </div>
              
              <div className="flex justify-between mt-6">
                <Button variant="outline" onClick={handlePrevious}>
                  Back
                </Button>
                <div className="space-x-2">
                  <Button variant="outline" onClick={handleNext}>
                    Skip
                  </Button>
                  <Button onClick={handleNext}>
                    Create Project
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
        
      default:
        return null;
    }
  };

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return "Add LinkedIn Profile";
      case 2:
        return "Name Your Project";
      case 3:
        return "Target Keywords";
      case 4:
        return "Add Competitors";
      default:
        return isCompleted ? "Your Profile is Ready!" : "Creating Project";
    }
  };

  const defaultTrigger = (
    <Button
      variant="ghost"
      className={`text-linkedin-primary hover:text-linkedin-dark transition-colors group ${className}`}
    >
      <Plus className="mr-2 h-4 w-4 transition-transform group-hover:scale-110" />
      Add Project
    </Button>
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children || defaultTrigger}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{getStepTitle()}</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          {renderStepContent()}
        </div>
      </DialogContent>
    </Dialog>
  );
};
