
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface ProjectLimitDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const ProjectLimitDialog = ({ open, onOpenChange }: ProjectLimitDialogProps) => {
  const navigate = useNavigate();
  
  const handleUpgrade = () => {
    onOpenChange(false);
    navigate('/pricing');
  };
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>You've Reached Your Project Limit</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <p className="text-muted-foreground">
            Upgrade your account to add more projects and track more keywords per project.
          </p>
          <div className="flex justify-end gap-3">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              No thanks
            </Button>
            <Button onClick={handleUpgrade}>
              Upgrade
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
