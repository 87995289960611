import { Button } from "@/components/ui/button";

export const CallToAction = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-linkedin-primary">
          Ready to Get Started?
        </h2>
        <Button 
          className="h-14 px-8 bg-[#F97316] hover:bg-[#EA580C] text-white font-semibold text-lg"
        >
          Get Started For Free
        </Button>
      </div>
    </section>
  );
};