
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "@/hooks/useSubscription";

export const usePricingPlan = (
  planName: string,
  autoOpenPayment?: boolean,
  onAutoOpenComplete?: () => void
) => {
  const { data: subscription } = useSubscription();
  const { isSignedIn, isLoaded } = useUser();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const navigate = useNavigate();
  
  const currentPlan = subscription?.plan?.toLowerCase() || 'free';
  const isTeamPlan = planName.toLowerCase() === 'team';

  // Handle automatic payment dialog opening
  useEffect(() => {
    if (autoOpenPayment && isLoaded) {
      console.log("Auto opening payment dialog", { isLoaded, isSignedIn });
      // Only auto-open if user is signed in
      if (isSignedIn) {
        setShowPaymentDialog(true);
        if (onAutoOpenComplete) {
          onAutoOpenComplete();
        }
      }
    }
  }, [autoOpenPayment, isLoaded, isSignedIn, onAutoOpenComplete]);
  
  const handlePricingAction = () => {
    // Don't proceed if this is the team plan
    if (isTeamPlan) return;
    
    console.log("Pricing action clicked", { isLoaded, isSignedIn });
    
    // Even if auth is still loading, we'll allow the click to proceed
    // This prevents buttons from being stuck in loading state if Clerk has issues
    
    if (isLoaded && !isSignedIn) {
      // Redirect to sign in with plan data in state
      navigate("/pricing/sign-in", {
        state: {
          selectedPlan: planName,
          billingPeriod: 'monthly' // Default to monthly
        }
      });
      return;
    }
    
    // If signed in or auth is loading, show payment dialog
    // This ensures users can still access payment even if Clerk is slow
    setShowPaymentDialog(true);
  };
  
  const getButtonConfig = () => {
    // Team plan is always "Coming Soon"
    if (isTeamPlan) {
      return {
        text: "Coming Soon",
        action: null,
        disabled: true,
        className: "bg-gray-400 cursor-not-allowed"
      };
    }
    
    // If auth is very slow to load (over 3 seconds), show the button anyway
    // This ensures users don't get stuck with disabled buttons
    const showActionEvenIfLoading = !isLoaded && document.readyState === 'complete';
    
    // Not fully loaded yet but we'll still make the button clickable after page is done loading
    if (!isLoaded && !showActionEvenIfLoading) {
      return {
        text: "Start Free Trial",
        action: handlePricingAction,
        disabled: false,
        className: planName.toLowerCase() === "individual"
          ? "bg-[#F97316] hover:bg-[#EA580C]"
          : planName.toLowerCase() === "team"
          ? "bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700"
          : "bg-gray-800 hover:bg-gray-900"
      };
    }
    
    // Guest user
    if (!isSignedIn || showActionEvenIfLoading) {
      return {
        text: "Start Free Trial",
        action: handlePricingAction,
        disabled: false,
        className: planName.toLowerCase() === "individual"
          ? "bg-[#F97316] hover:bg-[#EA580C]"
          : planName.toLowerCase() === "team"
          ? "bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700"
          : "bg-gray-800 hover:bg-gray-900"
      };
    }
    
    // Current plan
    if (currentPlan === planName.toLowerCase()) {
      return {
        text: "Current Plan",
        action: null,
        disabled: true,
        className: "bg-gray-400 cursor-not-allowed"
      };
    }
    
    const planRank = {
      'free': 0,
      'individual': 1,
      'pro': 2,
      'team': 3
    };
    
    const currentRank = planRank[currentPlan as keyof typeof planRank] || 0;
    const targetRank = planRank[planName.toLowerCase() as keyof typeof planRank] || 0;
    
    // Downgrade case
    if (currentRank > targetRank) {
      return {
        text: "Downgrade",
        action: handlePricingAction,
        disabled: false,
        className: "bg-gray-600 hover:bg-gray-700"
      };
    }
    
    // Upgrade case
    return {
      text: "Upgrade",
      action: handlePricingAction,
      disabled: false,
      className: planName.toLowerCase() === "individual"
        ? "bg-[#F97316] hover:bg-[#EA580C]"
        : planName.toLowerCase() === "team"
        ? "bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700"
        : "bg-gray-800 hover:bg-gray-900"
    };
  };

  return {
    showPaymentDialog,
    setShowPaymentDialog,
    buttonConfig: getButtonConfig(),
    isTeamPlan,
  };
};
