
import { ChevronRight } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LockedFeature } from "../LockedFeature";

interface ContentPerformanceProps {
  canAccessIndividual: boolean;
}

export const ContentPerformance = ({ canAccessIndividual }: ContentPerformanceProps) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
      <div>
        <h2 className="text-xl font-semibold mb-4">Content Performance</h2>
        {canAccessIndividual ? (
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Best Performing Post</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="p-4 border rounded-md">
                  <div className="flex justify-between mb-2">
                    <span className="font-medium">Posted on: May 15, 2023</span>
                    <span className="text-muted-foreground">10:32 AM</span>
                  </div>
                  <p className="text-gray-700 mb-3">
                    "Excited to announce our new partnership with LinkedIn to help professionals grow their online presence!"
                  </p>
                  <div className="flex space-x-4 text-sm text-muted-foreground">
                    <div>♥ 238 Likes</div>
                    <div>💬 42 Comments</div>
                    <div>↗ 18 Shares</div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <LockedFeature
            title="Content Performance"
            description="See your top performing posts and get insights on what works best for your audience."
            requiredPlan="Individual"
            className="h-64"
          />
        )}
      </div>
      
      <div>
        <h2 className="text-xl font-semibold mb-4">Recent Posts</h2>
        {canAccessIndividual ? (
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                {[1, 2, 3].map((index) => (
                  <div key={index} className="flex items-center justify-between p-3 border-b last:border-0">
                    <div className="flex-1">
                      <p className="text-sm line-clamp-1">
                        {index === 1 
                          ? "Just published my thoughts on LinkedIn growth strategies for 2023!" 
                          : index === 2 
                            ? "Check out our latest research on social media engagement metrics." 
                            : "Thanks everyone for the amazing feedback on my recent webinar!"}
                      </p>
                      <div className="flex space-x-3 text-xs text-muted-foreground mt-1">
                        <span>{index === 1 ? "125" : index === 2 ? "89" : "156"} Likes</span>
                        <span>{index === 1 ? "23" : index === 2 ? "12" : "34"} Comments</span>
                      </div>
                    </div>
                    <ChevronRight className="h-4 w-4 text-muted-foreground" />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        ) : (
          <LockedFeature
            title="Recent Posts Activity"
            description="Track the performance of your recent posts and identify trends."
            requiredPlan="Individual"
            className="h-64"
          />
        )}
      </div>
    </div>
  );
};
