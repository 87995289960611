
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MoreVertical, Plus, Lock, Link as LinkIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useState } from "react";
import { ProjectSettingsDialog } from "@/components/projects/ProjectSettingsDialog";

interface Profile {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
  isArchived?: boolean;
}

interface ProjectCardProps {
  id: string;
  name: string;
  profiles: Profile[];
  onSelect: () => void;
  onRename: (id: string, name: string) => void;
  onArchive: (id: string) => void;
  onDelete: (id: string) => void;
  canEdit?: boolean;
}

export const ProjectCard = ({ 
  id, 
  name, 
  profiles, 
  onSelect,
  onRename, 
  onArchive, 
  onDelete,
  canEdit = false
}: ProjectCardProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const activeProfiles = profiles.filter(p => !p.isArchived);
  const projectUrl = activeProfiles.length > 0 ? activeProfiles[0].url : '';

  const handleSaveSettings = (newName: string) => {
    onRename(id, newName);
  };

  const handleDeleteProject = () => {
    onDelete(id);
  };

  return (
    <>
      <Card className="hover:shadow-md transition-shadow">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-xl font-semibold">{name}</CardTitle>
          {canEdit ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => setIsSettingsOpen(true)}>
                  Project Settings
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onArchive(id)}>
                  Archive
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-red-600"
                  onClick={() => onDelete(id)}
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="sm" className="h-8 w-8 p-0 cursor-not-allowed opacity-50">
                    <Lock className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Upgrade to Individual plan to edit projects</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {activeProfiles.map((profile) => (
              <Button
                key={profile.id}
                variant="ghost"
                size="sm"
                className="w-full justify-start text-sm hover:bg-accent"
                onClick={onSelect}
              >
                {profile.name}
              </Button>
            ))}
            {canEdit && (
              <Button
                variant="outline"
                size="sm"
                className="w-full mt-4"
                onClick={onSelect}
              >
                <Plus className="h-4 w-4 mr-2" />
                View Dashboard
              </Button>
            )}
          </div>
        </CardContent>
      </Card>

      <ProjectSettingsDialog
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        projectName={name}
        projectUrl={projectUrl}
        onSave={handleSaveSettings}
        onDelete={handleDeleteProject}
      />
    </>
  );
};
