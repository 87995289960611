import { BarChart2, TrendingUp, LineChart, ChartBar } from "lucide-react";

const features = [
  {
    title: "Engagement Analytics",
    description: "Track your post performance and audience engagement in real-time",
    icon: ChartBar,
  },
  {
    title: "Content Performance",
    description: "Analyze which content types resonate most with your audience",
    icon: LineChart,
  },
  {
    title: "Competitor Benchmarking",
    description: "Compare your growth and engagement with industry peers",
    icon: BarChart2,
  },
  {
    title: "Predictive Insights",
    description: "AI-powered recommendations for optimal posting times and content",
    icon: TrendingUp,
  },
];

export const Features = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-linkedin-primary animate-fade-up">
          Powerful Features, Seamless Insights
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-8 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105 border border-gray-100 animate-fade-up text-center"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="bg-gradient-to-br from-orange-50 to-orange-100 w-20 h-20 rounded-2xl flex items-center justify-center mb-6 mx-auto transform transition-transform hover:rotate-6">
                <feature.icon className="w-10 h-10 text-[#F97316]" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
              <p className="text-gray-600 leading-relaxed">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};