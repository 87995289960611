
import { useNavigate } from "react-router-dom";
import { Hero } from "@/components/Hero";
import { Features } from "@/components/Features";
import { BlogPreview } from "@/components/BlogPreview";
import { CallToAction } from "@/components/CallToAction";
import { Footer } from "@/components/Footer";

const Index = () => {
  const navigate = useNavigate();
  const isDevelopment = process.env.NODE_ENV === 'development' || window.location.hostname.includes('lovable.dev');

  if (typeof window !== 'undefined' && !isDevelopment) {
    const params = new URLSearchParams(window.location.search);
    const hasLinkedInCode = params.has('code');
    if (hasLinkedInCode) {
      navigate('/dashboard');
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1">
        <Hero />
        <Features />
        <BlogPreview />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Index;
