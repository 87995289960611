
import { useEffect } from "react";

export const useLemonSqueezyStyles = () => {
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      #root {
        position: static !important;
        overflow: visible !important;
      }
      body {
        position: relative !important;
        overflow: auto !important;
      }
      .lemonsqueezy-modal,
      .lemon-squeezy-modal {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        z-index: 99999 !important;
        background: rgba(0, 0, 0, 0.5) !important;
        padding: 1rem !important;
        box-sizing: border-box !important;
      }
      .lemonsqueezy-modal iframe,
      .lemon-squeezy-modal iframe {
        width: 100% !important;
        max-width: 500px !important;
        min-height: 600px !important;
        max-height: 90vh !important;
        margin: 0 auto !important;
        border: none !important;
        border-radius: 8px !important;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.1) !important;
        background: white !important;
      }
      @media (min-width: 768px) {
        .lemonsqueezy-modal iframe,
        .lemon-squeezy-modal iframe {
          width: 90vw !important;
          max-width: 900px !important;
          height: auto !important;
        }
      }
      /* Hide billing address and tax ID fields */
      .lemonsqueezy-modal iframe #billing-address-collector,
      .lemon-squeezy-modal iframe #billing-address-collector,
      .lemonsqueezy-modal iframe #tax-id-collector,
      .lemon-squeezy-modal iframe #tax-id-collector {
        display: none !important;
      }
      /* Hide billing address section */
      .lemonsqueezy-modal iframe [data-testid="checkout-shipping-address"],
      .lemon-squeezy-modal iframe [data-testid="checkout-shipping-address"],
      .lemonsqueezy-modal iframe [data-testid="checkout-billing-address"],
      .lemon-squeezy-modal iframe [data-testid="checkout-billing-address"] {
        display: none !important;
      }
      /* Hide tax ID section */
      .lemonsqueezy-modal iframe [data-testid="checkout-tax-id"],
      .lemon-squeezy-modal iframe [data-testid="checkout-tax-id"] {
        display: none !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      if (document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);
};
