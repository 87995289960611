
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { testBrightDataConnection } from "@/utils/linkedinReportAPI";
import { useToast } from "@/components/ui/use-toast";
import { AlertCircle, RefreshCw } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const BrightDataConnectionTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testUrl, setTestUrl] = useState("https://www.linkedin.com/in/gal-mor/");
  const [isPro, setIsPro] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  const runConnectionTest = async () => {
    if (!testUrl) {
      toast({
        title: "URL Required",
        description: "Please enter a LinkedIn URL to test",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await testBrightDataConnection(testUrl);
      setResult(response);
      
      toast({
        title: "Test Completed",
        description: "The echo test was completed successfully. Check the results below.",
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      
      toast({
        title: "Test Failed",
        description: err instanceof Error ? err.message : "An unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-end gap-2">
        <div className="flex-1">
          <label htmlFor="test-url" className="block text-sm font-medium mb-1">
            LinkedIn URL for Echo Test
          </label>
          <Input
            id="test-url"
            value={testUrl}
            onChange={(e) => setTestUrl(e.target.value)}
            placeholder="https://www.linkedin.com/in/username/"
          />
        </div>
        <div className="flex items-center mr-4">
          <label htmlFor="is-pro-test" className="text-sm font-medium mr-2">
            Pro Version
          </label>
          <Switch
            id="is-pro-test"
            checked={isPro}
            onCheckedChange={setIsPro}
          />
        </div>
        <Button 
          onClick={runConnectionTest} 
          disabled={isLoading}
          className="gap-2"
        >
          {isLoading ? (
            <>
              <RefreshCw className="h-4 w-4 animate-spin" />
              Testing...
            </>
          ) : (
            "Run Echo Test"
          )}
        </Button>
      </div>
      
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      {result && (
        <div className="border rounded-md p-4 bg-gray-50">
          <h3 className="font-medium mb-2">Echo Test Results</h3>
          <pre className="text-xs overflow-auto bg-white p-3 border rounded max-h-[400px]">
            {JSON.stringify(result, null, 2)}
          </pre>
        </div>
      )}
      
      <div className="text-sm text-gray-500 border-t pt-4 mt-4">
        <p>This test calls a separate edge function that simply echoes back the request parameters without making any API calls to BrightData.</p>
        <p>It helps verify what data is being sent from the frontend to the Edge Function.</p>
        <p className="mt-2 italic">
          Note: BrightData has two different datasets:
          <ul className="list-disc ml-6 mt-1">
            <li>Standard version uses the LinkedIn profiles dataset (<code>gd_l1viktl72bvl7bjuj0</code>)</li>
            <li>Pro version uses the LinkedIn posts dataset (<code>gd_lyy3tktm25m4avu764</code>)</li>
          </ul>
        </p>
      </div>
    </div>
  );
};
