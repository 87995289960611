
import { BrightDataDebugger } from "@/components/brightdata/BrightDataDebugger";

export default function BrightDataDebugPage() {
  // Get the Supabase URL from environment or use the default URL
  const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || "https://dnwgkklwadvoxfbospfh.supabase.co";
  const apiUrl = `${supabaseUrl}/functions/v1/brightdata-test-direct`;

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-2xl font-bold mb-6">BrightData API Debugging</h1>
      
      <div className="mb-6 bg-blue-50 border border-blue-200 rounded-md p-4 text-blue-800">
        <h2 className="text-lg font-medium mb-2">API Endpoint Information</h2>
        <p className="mb-2">
          This debugger will call the following edge function:
        </p>
        <code className="block p-2 bg-blue-100 rounded text-sm mb-2 overflow-auto">
          {apiUrl}
        </code>
        <p>Make sure this endpoint exists and is properly deployed in your Supabase project.</p>
      </div>
      
      <BrightDataDebugger />
      
      <div className="mt-8 bg-amber-50 border border-amber-200 rounded-md p-4 text-amber-800">
        <h2 className="text-lg font-medium mb-2">Common 401 Error Causes</h2>
        <ul className="list-disc pl-5 space-y-1">
          <li>Invalid API key format (should be a string of characters)</li>
          <li>Expired or revoked API key</li>
          <li>API key doesn't have permission for the requested dataset</li>
          <li>Whitespace or invisible characters in the API key</li>
          <li>Rate limiting or quota exhaustion on the BrightData side</li>
          <li>Network issues between Supabase Edge Functions and BrightData</li>
        </ul>
        <p className="mt-3">The detailed logs from this debugger will help identify the exact cause of the 401 error.</p>
      </div>
    </div>
  );
}
