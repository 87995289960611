import { Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { Header } from "@/components/Header";
import Index from "./pages/Index";
import Dashboard from "./pages/Dashboard";
import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import PricingPage from "./pages/PricingPage";
import CallbackPage from "./pages/CallbackPage";
import BlogIndex from "./pages/BlogIndex";
import BlogPost from "./pages/BlogPost";
import AllPosts from "./pages/AllPosts";
import { PricingSignIn } from "./components/pricing/PricingSignIn";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import UserProfileLayout from "./pages/UserProfileLayout";
import { Navigate } from "react-router-dom";
import ProfileAudit from "./pages/ProfileAudit";
import BrightDataGuidePage from "./pages/BrightDataGuidePage";
import BrightDataIntegrationPage from "./pages/BrightDataIntegrationPage";
import BrightDataTest from "./pages/BrightDataTest";
import BrightDataDebugPage from "./pages/BrightDataDebugPage";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/sign-in/*" element={<SignInPage />} />
        <Route path="/sign-up/*" element={<SignUpPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pricing/sign-in/*" element={<PricingSignIn />} />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="/blog" element={<BlogIndex />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/posts" element={<AllPosts />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/user/*" element={<UserProfileLayout />} />
        <Route path="/audit" element={<ProfileAudit />} />
        <Route path="/brightdata-guide" element={<BrightDataGuidePage />} />
        <Route path="/brightdata-integration" element={<BrightDataIntegrationPage />} />
        <Route path="/brightdata-test" element={<BrightDataTest />} />
        <Route path="/brightdata-debug" element={<BrightDataDebugPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
