import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Share2, Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

interface TimeframeBannerProps {
  start: string;
  end: string;
  isPro?: boolean;
  onTimeframeChange?: (value: string) => void;
}

export const TimeframeBanner = ({ start, end, isPro = false, onTimeframeChange }: TimeframeBannerProps) => {
  const { toast } = useToast();
  const timeframes = [
    { label: "Last 30 days", value: "30d" },
    { label: "Last 90 days", value: "90d" },
    { label: "Last 6 months", value: "6m" },
    { label: "Last year", value: "1y" },
  ];

  const handleShare = async () => {
    try {
      const shareData = {
        title: 'LinkedIn Analytics Report',
        text: `LinkedIn Analytics Report for period ${start} - ${end}`,
        url: window.location.href,
      };

      if (navigator.share && navigator.canShare(shareData)) {
        await navigator.share(shareData);
        toast({
          title: "Shared Successfully",
          description: "The report has been shared.",
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        toast({
          title: "Link Copied",
          description: "Report URL has been copied to clipboard.",
        });
      }
    } catch (error) {
      await navigator.clipboard.writeText(window.location.href);
      toast({
        title: "Link Copied",
        description: "Report URL has been copied to clipboard.",
        variant: "default",
      });
    }
  };

  const handleExport = () => {
    // In a real application, this would generate and download a PDF/CSV
    toast({
      title: "Export Started",
      description: "Your report is being generated and will download shortly.",
    });
    
    // Simulate download delay
    setTimeout(() => {
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent("LinkedIn Analytics Report"));
      element.setAttribute("download", `linkedin-report-${start}-${end}.txt`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      
      toast({
        title: "Export Complete",
        description: "Your report has been downloaded.",
      });
    }, 1500);
  };

  return (
    <div className="bg-gradient-to-r from-linkedin-primary/5 to-linkedin-dark/5 p-4 rounded-lg">
      <div className="flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="flex items-center gap-4">
          <div className="text-sm font-medium text-linkedin-primary">
            {isPro ? "Pro Report Analysis Period:" : "Free Report Analysis Period:"} {start} - {end}
          </div>
          {isPro && (
            <Select onValueChange={onTimeframeChange}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select timeframe" />
              </SelectTrigger>
              <SelectContent>
                {timeframes.map((timeframe) => (
                  <SelectItem key={timeframe.value} value={timeframe.value}>
                    {timeframe.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        {isPro && (
          <div className="flex gap-2">
            <Button 
              variant="outline" 
              size="sm" 
              className="flex items-center gap-2 hover:bg-linkedin-primary/10 transition-colors" 
              onClick={handleShare}
            >
              <Share2 className="w-4 h-4" />
              Share
            </Button>
            <Button 
              variant="outline" 
              size="sm" 
              className="flex items-center gap-2 hover:bg-linkedin-primary/10 transition-colors" 
              onClick={handleExport}
            >
              <Download className="w-4 h-4" />
              Export
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};