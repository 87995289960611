
import { useState } from "react";
import { LineChart, ArrowUp } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LockedFeature } from "../LockedFeature";
import {
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

interface FollowerGrowthProps {
  canAccessIndividual: boolean;
}

export const FollowerGrowth = ({ canAccessIndividual }: FollowerGrowthProps) => {
  // Sample follower growth data
  const [followerData] = useState([
    { month: "Jan", followers: 2850 },
    { month: "Feb", followers: 3050 },
    { month: "Mar", followers: 3210 },
    { month: "Apr", followers: 3340 },
    { month: "May", followers: 3420 },
    { month: "Jun", followers: 3580 },
    { month: "Jul", followers: 3665 },
    { month: "Aug", followers: 3865 },
  ]);

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold mb-4">Follower Growth</h2>
      {canAccessIndividual ? (
        <Card>
          <CardHeader className="pb-0">
            <CardTitle className="text-lg font-medium">Follower Growth Trend</CardTitle>
            <div className="flex items-center text-sm text-green-600">
              <ArrowUp className="h-4 w-4 mr-1" />
              35.6% growth this year
            </div>
          </CardHeader>
          <CardContent className="pt-4 h-80">
            <ResponsiveContainer width="100%" height="100%">
              <RechartsLineChart data={followerData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="month" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} tickFormatter={(value) => `${value}`} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="followers"
                  stroke="#ff6b00"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
              </RechartsLineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      ) : (
        <LockedFeature
          title="Follower Growth Analytics"
          description="Track your follower growth over time and identify trends."
          requiredPlan="Individual"
          className="h-80"
        />
      )}
    </div>
  );
};
