
import { Button } from "@/components/ui/button";
import { Info } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export const BrightDataInfo = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <Info className="h-4 w-4 mr-2" />
          About BrightData
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 text-sm">
        <div className="space-y-2">
          <h4 className="font-medium">What is BrightData?</h4>
          <p>BrightData is a data collection platform that allows TrackInfluence to securely access LinkedIn profile and post data.</p>
          <h4 className="font-medium mt-4">How to get API keys:</h4>
          <ol className="list-decimal pl-4 space-y-1">
            <li>Sign up at <a href="https://brightdata.com" target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">brightdata.com</a></li>
            <li>Navigate to API section in your dashboard</li>
            <li>Create an API key with LinkedIn data access permissions</li>
            <li>Copy and paste the key here</li>
          </ol>
        </div>
      </PopoverContent>
    </Popover>
  );
};
