import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { BlogSidebar } from "@/components/BlogSidebar";

const BlogPost = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Full-width featured area */}
      <div className="w-full bg-white">
        <div className="container mx-auto px-4 py-12">
          <Link to="/blog" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Blog
          </Link>
          
          <div className="grid md:grid-cols-2 gap-12 items-center px-8">
            <div className="flex flex-col justify-center space-y-6">
              <h1 className="text-3xl md:text-4xl font-bold">
                The Role of Web3 in Influencer Marketing in 2025
              </h1>
              
              <div className="flex items-center gap-6">
                <img
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
                  alt="Author"
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <div className="font-semibold">Cameron Coward</div>
                  <div className="text-gray-600 text-sm">Last Updated: Oct 20, 2024</div>
                </div>
              </div>
            </div>
            
            <img
              src="https://images.unsplash.com/photo-1498050108023-c5249f4df085"
              alt="Featured"
              className="w-full h-[300px] object-cover rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* Content area with sidebar */}
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Main Content */}
          <div className="flex-1">
            <article className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-8">
                <div className="prose max-w-none">
                  <p className="lead text-xl text-gray-600 mb-8">
                    Web3 is transforming influencer marketing and brands need to embrace this new frontier.
                  </p>
                  
                  <h2 className="text-2xl font-bold mt-12 mb-6">What Exactly Is Web3, and Why Should You Care?</h2>
                  <p className="mb-6">
                    Web3 is a decentralized version of the Internet where blockchain technology is used to ensure transparency,
                    authenticity, and user ownership. For influencer marketing, this means a shift from traditional social media
                    platforms to decentralized networks where creators have more control over their content and engagement with
                    their audience.
                  </p>
                  
                  <img
                    src="https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7"
                    alt="Web3 Illustration"
                    className="w-full h-[400px] object-cover rounded-lg my-12"
                  />
                  
                  <h2 className="text-2xl font-bold mt-12 mb-6">The Impact on Influencer Marketing</h2>
                  <p className="mb-6">
                    As we move towards 2025, the integration of Web3 technologies in influencer marketing is becoming increasingly
                    important. Brands and influencers alike are discovering new ways to create value and build stronger
                    relationships with their audiences through blockchain-based solutions.
                  </p>
                  
                  <div className="my-12 aspect-video">
                    <iframe
                      className="w-full h-full rounded-lg"
                      src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                      title="Web3 and Influencer Marketing"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  
                  <h2 className="text-2xl font-bold mt-12 mb-6">Key Benefits of Web3 in Influencer Marketing</h2>
                  <p className="mb-6">
                    The implementation of Web3 technologies in influencer marketing brings several advantages that can't be
                    ignored. From improved transparency to better ROI tracking, the benefits are substantial and far-reaching.
                  </p>
                </div>
                
                <div className="mt-12 border-t pt-8 flex justify-center">
                  <Button className="bg-[#F97316] hover:bg-[#EA580C] text-white px-8">
                    Start Tracking Your LinkedIn Influence
                  </Button>
                </div>
              </div>
            </article>
          </div>
          
          {/* Sidebar - Hidden on mobile */}
          <div className="hidden md:block w-[200px]">
            <div className="sticky top-8">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;