
import { supabase } from "@/integrations/supabase/client";

// Regular expression for valid LinkedIn profile URLs
const linkedinProfileUrlRegex = /.*linkedin\.com\/in\/[a-zA-Z0-9_-]+.*$/;

interface LinkedInReportOptions {
  url: string;
  isPro?: boolean;
  isIndividual?: boolean;
  useTestEndpoint?: boolean; // Flag to use the test endpoint instead
}

export const fetchLinkedInReport = async ({ 
  url, 
  isPro = false, 
  isIndividual = true,
  useTestEndpoint = false 
}: LinkedInReportOptions) => {
  try {
    // Check if the API key is set in localStorage
    const apiKey = localStorage.getItem("brightdata_api_key")?.trim();
    const fallbackApiKey = localStorage.getItem("brightdata_profiles_api_key")?.trim(); // Fallback for legacy storage
    const effectiveApiKey = apiKey || fallbackApiKey;

    console.log("Calling fetchLinkedInReport with:", { url, isPro, isIndividual, useTestEndpoint });
    console.log("API key available:", !!effectiveApiKey, effectiveApiKey ? `length: ${effectiveApiKey.length}` : '');

    // Validate URL
    if (!url) {
      console.error("LinkedIn URL is missing");
      throw new Error("LinkedIn URL is required");
    }
    
    // Validate URL format - always expecting a LinkedIn profile URL for initial input
    const isProfileUrl = linkedinProfileUrlRegex.test(url);
    
    if (!isProfileUrl) {
      console.error("Invalid LinkedIn URL format");
      throw new Error(
        "A valid LinkedIn profile URL is required in the format: linkedin.com/in/username"
      );
    }

    // Create request object
    const requestPayload = { 
      url, 
      isPro, 
      isIndividual,
      apiKey: effectiveApiKey, // Pass the API key from localStorage to the edge function
    };

    console.log("Request payload prepared:", { 
      url: requestPayload.url,
      isPro: requestPayload.isPro, 
      isIndividual: requestPayload.isIndividual,
      hasApiKey: !!requestPayload.apiKey
    });

    // Determine which endpoint to use
    const functionName = useTestEndpoint ? "linkedin-echo-test" : "generate-linkedin-report";
    console.log(`Using Supabase client to invoke ${functionName} function`);
    
    // Call the edge function using Supabase client (handles authentication automatically)
    const { data, error } = await supabase.functions.invoke(functionName, {
      body: requestPayload,
    });

    if (error) {
      console.error(`Error invoking ${functionName} function:`, error);
      
      // More detailed error logging to help diagnose the issue
      if (error.message && error.message.includes("non-2xx")) {
        console.error("Edge function returned a non-2xx status code. This often indicates:");
        console.error("1. The BrightData API key might be invalid or expired");
        console.error("2. There might be an issue with the URL format");
        console.error("3. The BrightData service might be temporarily unavailable");
        console.error("4. Edge function might have internal errors");
      }
      
      throw new Error(error.message || `Failed to call LinkedIn API via ${functionName}`);
    }

    if (!data) {
      console.error("No data returned from edge function");
      throw new Error("No data returned from the LinkedIn API");
    }

    console.log("LinkedIn API response received:", {
      success: true,
      dataLength: data ? Object.keys(data).length : 0,
      functionUsed: functionName
    });
    
    return data;

  } catch (error) {
    console.error("Error in fetchLinkedInReport:", error);
    throw error;
  }
};

// Debug utility to test the connection
export const testBrightDataConnection = async (url: string) => {
  try {
    console.log("Testing BrightData connection with echo endpoint...");
    return await fetchLinkedInReport({ 
      url, 
      useTestEndpoint: true 
    });
  } catch (error) {
    console.error("BrightData test connection failed:", error);
    throw error;
  }
};
