
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

interface Profile {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
  isArchived?: boolean;
}

interface Project {
  id: string;
  name: string;
  profiles: Profile[];
}

export const useDashboardProjects = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<{ id: string; name: string } | null>(null);
  const [activeProject, setActiveProject] = useState<Project | null>(null);
  const [viewMode, setViewMode] = useState<"grid" | "detail">("grid");
  const { toast } = useToast();
  const { canAccessIndividual } = usePlanFeatures();

  useEffect(() => {
    const loadProjects = () => {
      const savedProjects = JSON.parse(localStorage.getItem('projects') || '[]');
      setProjects(savedProjects);
      
      if (savedProjects.length > 0 && !activeProject) {
        setActiveProject(savedProjects[0]);
        setViewMode("detail");
      }
    };

    loadProjects();
    window.addEventListener('storageUpdated', loadProjects);
    return () => {
      window.removeEventListener('storageUpdated', loadProjects);
    };
  }, [activeProject]);

  const handleDeleteProject = () => {
    if (!selectedProject) return;
    
    if (!canAccessIndividual) {
      toast({
        title: "Feature locked",
        description: "Upgrade to Individual or higher plan to delete projects.",
        variant: "destructive",
      });
      setIsDeleteDialogOpen(false);
      return;
    }

    const updatedProjects = projects.filter(project => project.id !== selectedProject.id);
    localStorage.setItem('projects', JSON.stringify(updatedProjects));
    window.dispatchEvent(new Event('storageUpdated'));
    setProjects(updatedProjects);
    
    if (activeProject && activeProject.id === selectedProject.id) {
      setActiveProject(updatedProjects.length > 0 ? updatedProjects[0] : null);
      setViewMode(updatedProjects.length > 0 ? "detail" : "grid");
    }
    
    setIsDeleteDialogOpen(false);
    setSelectedProject(null);
    
    toast({
      title: "Project Deleted",
      description: "The project has been deleted successfully.",
    });
  };

  const handleArchiveProject = (projectId: string) => {
    if (!canAccessIndividual) {
      toast({
        title: "Feature locked",
        description: "Upgrade to Individual or higher plan to archive projects.",
        variant: "destructive",
      });
      return;
    }

    const updatedProjects = projects.map(project =>
      project.id === projectId
        ? {
            ...project,
            profiles: project.profiles.map(profile => ({ ...profile, isArchived: true }))
          }
        : project
    );

    localStorage.setItem('projects', JSON.stringify(updatedProjects));
    window.dispatchEvent(new Event('storageUpdated'));
    setProjects(updatedProjects);
    
    toast({
      title: "Project Archived",
      description: "The project has been archived successfully.",
    });
  };
  
  const handleProjectSelect = (project: Project) => {
    setActiveProject(project);
    setViewMode("detail");
  };
  
  const handleBackToProjects = () => {
    setViewMode("grid");
    setActiveProject(null);
  };

  const handleRenameActiveProject = (newName: string) => {
    if (!activeProject) return;
    
    const updatedProjects = projects.map(project =>
      project.id === activeProject.id
        ? { ...project, name: newName }
        : project
    );

    localStorage.setItem('projects', JSON.stringify(updatedProjects));
    window.dispatchEvent(new Event('storageUpdated'));
    setProjects(updatedProjects);
    setActiveProject({...activeProject, name: newName});
    
    toast({
      title: "Project Updated",
      description: "The project has been updated successfully.",
    });
  };
  
  const handleDeleteActiveProject = () => {
    if (!activeProject) return;
    
    const updatedProjects = projects.filter(project => project.id !== activeProject.id);
    localStorage.setItem('projects', JSON.stringify(updatedProjects));
    window.dispatchEvent(new Event('storageUpdated'));
    setProjects(updatedProjects);
    
    setActiveProject(updatedProjects.length > 0 ? updatedProjects[0] : null);
    setViewMode(updatedProjects.length > 0 ? "detail" : "grid");
    
    toast({
      title: "Project Deleted",
      description: "The project has been deleted successfully.",
    });
  };

  const openDeleteDialog = (id: string, name: string) => {
    setSelectedProject({ id, name });
    setIsDeleteDialogOpen(true);
  };

  return {
    projects,
    selectedProject,
    activeProject,
    viewMode,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    handleDeleteProject,
    handleArchiveProject,
    handleProjectSelect,
    handleBackToProjects,
    handleRenameActiveProject,
    handleDeleteActiveProject,
    openDeleteDialog
  };
};
