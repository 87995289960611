import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link to="/" className="flex items-center gap-2">
      <img 
        src="/lovable-uploads/10e1892c-4137-45ce-9737-609c04039ed1.png" 
        alt="TrackInfluence" 
        className="h-16"
      />
    </Link>
  );
};