
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { AlertCircle, CheckCircle, ClipboardCopy } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

export const BrightDataDebugger = () => {
  const [apiKey, setApiKey] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const { toast } = useToast();

  const handleTest = async () => {
    if (!apiKey.trim()) {
      toast({
        title: "API Key Required",
        description: "Please enter your BrightData API key",
        variant: "destructive",
      });
      return;
    }

    if (!profileUrl.trim()) {
      toast({
        title: "LinkedIn URL Required",
        description: "Please enter a LinkedIn profile URL to test",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      console.log("Testing BrightData API with Supabase client...");
      
      // Use Supabase client to call the edge function
      // This handles authentication automatically
      const { data, error } = await supabase.functions.invoke("brightdata-test-direct", {
        body: {
          apiKey: apiKey.trim(),
          url: profileUrl.trim(),
        },
      });

      if (error) {
        console.error("Error invoking Supabase function:", error);
        setResult({
          success: false,
          error: error.message,
          timestamp: new Date().toISOString(),
        });
        
        toast({
          title: "Test Failed",
          description: error.message || "Failed to call BrightData API",
          variant: "destructive",
        });
      } else {
        console.log("Received successful response:", data);
        setResult({
          success: data.success,
          data,
          timestamp: new Date().toISOString(),
        });

        if (data.success) {
          toast({
            title: "Test Successful",
            description: "BrightData API test completed successfully",
          });
        } else {
          toast({
            title: "Test Failed",
            description: data.error || "The BrightData API test failed",
            variant: "destructive",
          });
        }
      }
    } catch (error) {
      console.error("Exception during API test:", error);
      setResult({
        success: false,
        error: error instanceof Error ? error.message : String(error),
        timestamp: new Date().toISOString(),
      });
      
      toast({
        title: "Request Failed",
        description: "Failed to send request to BrightData API",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      description: "The data has been copied to your clipboard",
    });
  };

  // Helper function to safely convert unknown values to string for display
  const safeStringify = (value: unknown): string => {
    if (value === null) return "null";
    if (value === undefined) return "undefined";
    
    try {
      return typeof value === 'object' 
        ? JSON.stringify(value, null, 2) 
        : String(value);
    } catch (error) {
      return `[Error displaying value: ${error instanceof Error ? error.message : 'Unknown error'}]`;
    }
  };

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle>BrightData API Debugger</CardTitle>
        <CardDescription>
          Test your BrightData API key and view detailed request/response information
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="apiKey" className="font-medium">BrightData API Key</label>
          <Input
            id="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            type="password"
            placeholder="Enter your BrightData API key"
            className="font-mono"
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="profileUrl" className="font-medium">LinkedIn Profile URL</label>
          <Input
            id="profileUrl"
            value={profileUrl}
            onChange={(e) => setProfileUrl(e.target.value)}
            placeholder="https://www.linkedin.com/in/username"
          />
        </div>
      </CardContent>
      <CardFooter className="flex-col items-start space-y-4">
        <Button 
          onClick={handleTest} 
          disabled={isLoading || !apiKey.trim() || !profileUrl.trim()}
          className="w-full"
        >
          {isLoading ? "Testing..." : "Test BrightData API"}
        </Button>

        {result && (
          <div className="w-full">
            <div className="flex items-center mb-4 gap-2">
              {result.success ? (
                <Badge variant="outline" className="bg-green-50 text-green-800 border-green-200">
                  <CheckCircle className="w-4 h-4 mr-1" /> Success
                </Badge>
              ) : (
                <Badge variant="outline" className="bg-red-50 text-red-800 border-red-200">
                  <AlertCircle className="w-4 h-4 mr-1" /> Failed
                </Badge>
              )}
              <span className="text-sm text-gray-500">Tested at {new Date(result.timestamp).toLocaleTimeString()}</span>
            </div>

            <Tabs defaultValue="response">
              <TabsList className="w-full">
                <TabsTrigger value="response">Response</TabsTrigger>
                <TabsTrigger value="raw">Raw Data</TabsTrigger>
              </TabsList>
              
              <TabsContent value="response" className="p-4 border rounded-md mt-2">
                {result.data && result.data.status && (
                  <div className="mb-4">
                    <div className="font-medium">Status: <span className="font-mono">{String(result.data.status)} {String(result.data.statusText || '')}</span></div>
                  </div>
                )}

                {!result.success && result.error && (
                  <Alert variant="destructive" className="mb-4">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{String(result.error)}</AlertDescription>
                  </Alert>
                )}

                {result.data && result.data.error && (
                  <Alert variant="destructive" className="mb-4">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>API Error</AlertTitle>
                    <AlertDescription>{String(result.data.error)}</AlertDescription>
                  </Alert>
                )}

                {result.data && result.data.details && (
                  <Alert className="mb-4">
                    <AlertTitle>Error Details</AlertTitle>
                    <AlertDescription className="font-mono text-xs whitespace-pre-wrap">
                      {String(result.data.details)}
                    </AlertDescription>
                  </Alert>
                )}

                {result.data && result.data.success && (
                  <Alert className="bg-green-50 border-green-200 mb-4">
                    <CheckCircle className="h-4 w-4 text-green-500" />
                    <AlertTitle className="text-green-700">Success</AlertTitle>
                    <AlertDescription>
                      {String(result.data.message || "The request was successful")}
                      {result.data.snapshotId && (
                        <div className="mt-2">
                          <span className="font-medium">Snapshot ID: </span>
                          <code className="px-2 py-1 bg-green-100 rounded font-mono">{String(result.data.snapshotId)}</code>
                        </div>
                      )}
                    </AlertDescription>
                  </Alert>
                )}
              </TabsContent>

              <TabsContent value="raw" className="mt-2">
                <div className="relative">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className="absolute right-2 top-2"
                    onClick={() => copyToClipboard(safeStringify(result))}
                  >
                    <ClipboardCopy className="h-4 w-4 mr-1" /> Copy
                  </Button>
                  <pre className="p-4 border rounded-md bg-gray-50 overflow-auto text-xs font-mono max-h-[400px]">
                    {safeStringify(result)}
                  </pre>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};
