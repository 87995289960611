
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { supabase } from './integrations/supabase/client'

// Create a new query client instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 1,
    },
  },
})

// Set a default publishable key to use while loading the correct one
let clerkPubKey = "";

// Create a component to handle loading the Clerk key
const ClerkProviderWithKey: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [clerkKey, setClerkKey] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const loadClerkKey = async () => {
      try {
        // Determine if we should use production or test key
        const isProd = !import.meta.env.DEV && 
          !window.location.hostname.includes('lovable.dev') && 
          !window.location.hostname.includes('preview--');
        
        const keyName = isProd ? 'CLERK_PROD_PUBLISHABLE_KEY' : 'CLERK_PUBLISHABLE_KEY';
        
        // Fetch the key from Supabase
        const { data, error } = await supabase
          .from('app_config')
          .select('value')
          .eq('key', keyName)
          .single();

        if (error) {
          console.error('Error fetching Clerk key:', error);
          setError(`Failed to load authentication configuration: ${error.message}`);
          setIsLoading(false);
          return;
        }

        if (data) {
          console.log(`Using ${keyName} for Clerk authentication`);
          setClerkKey(data.value);
        } else {
          setError('Authentication configuration not found');
        }
      } catch (err) {
        console.error('Unexpected error loading Clerk key:', err);
        setError('An unexpected error occurred while setting up authentication');
      } finally {
        setIsLoading(false);
      }
    };

    loadClerkKey();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900">Loading...</h2>
          <p className="mt-2 text-gray-600">Please wait while we set up authentication.</p>
        </div>
      </div>
    );
  }

  if (error || !clerkKey) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center max-w-md px-4">
          <h2 className="text-2xl font-semibold text-red-600">Authentication Error</h2>
          <p className="mt-2 text-gray-600">{error || 'Failed to load authentication configuration'}</p>
        </div>
      </div>
    );
  }

  return (
    <ClerkProvider 
      publishableKey={clerkKey}
      appearance={{
        elements: {
          formButtonPrimary: 'bg-linkedin-primary hover:bg-linkedin-dark',
          rootBox: 'w-full',
          card: 'rounded-lg shadow-md',
          socialButtonsBlockButton: 'border border-gray-300 hover:bg-gray-50',
          formFieldInput: 'rounded border-gray-300 focus:border-linkedin-primary focus:ring-linkedin-primary',
          footerActionLink: 'text-linkedin-primary hover:text-linkedin-dark'
        }
      }}
      // Add routing configurations
      afterSignInUrl="/dashboard"
      afterSignUpUrl="/dashboard"
    >
      {children}
    </ClerkProvider>
  );
};

// Create the root component with proper configuration
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ClerkProviderWithKey>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ClerkProviderWithKey>
    </QueryClientProvider>
  </React.StrictMode>,
)
