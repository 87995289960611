
import { Lock } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface LockedFeatureCardProps {
  title: string;
  description: string;
  requiredPlan: string;
  className?: string;
}

export const LockedFeatureCard = ({ 
  title, 
  description, 
  requiredPlan, 
  className = "" 
}: LockedFeatureCardProps) => {
  const navigate = useNavigate();
  
  return (
    <Card className={`relative overflow-hidden bg-gray-50 ${className}`}>
      <CardHeader className="pb-2">
        <CardTitle className="text-sm font-medium text-muted-foreground">
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="opacity-50">
        <div className="text-2xl font-bold text-gray-400">--</div>
        <div className="flex items-center mt-1 text-muted-foreground">
          <span className="text-sm">Locked feature</span>
        </div>
      </CardContent>
      <div className="absolute inset-0 flex items-center justify-center bg-white/60 backdrop-blur-[1px]">
        <div className="p-4 bg-gray-50 rounded-md shadow-sm text-center">
          <Lock className="h-5 w-5 mx-auto mb-2 text-orange-500" />
          <span className="text-sm font-medium block mb-2">
            Upgrade to {requiredPlan}
          </span>
          <Button 
            size="sm"
            onClick={() => navigate('/pricing')} 
            className="bg-orange-500 hover:bg-orange-600 text-white"
          >
            Upgrade Now
          </Button>
        </div>
      </div>
    </Card>
  );
};
