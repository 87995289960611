import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import { BlogSidebar } from "@/components/BlogSidebar";

const posts = [
  {
    title: "5 Strategies to Boost Your ROI in Influencer Marketing",
    description: "5 strategies to help you boost your ROI in influencer marketing and make the most out of your campaigns.",
    image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d",
    date: "Published about 2 months ago",
    slug: "boost-roi-strategies"
  },
  {
    title: "The Role of Web3 in Influencer Marketing in 2025",
    description: "Web3 is transforming influencer marketing and brands need to embrace this new frontier.",
    image: "https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7",
    date: "Published about 2 months ago",
    slug: "web3-influencer-marketing"
  },
  {
    title: "Why Is It Important to Track Your Influencer ROI?",
    description: "Tracking your Influencer ROI is essential for understanding which influencers and campaigns drive results.",
    image: "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
    date: "Published about 2 months ago",
    slug: "importance-of-roi-tracking"
  }
];

const BlogIndex = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Main Content */}
          <div className="flex-1">
            <Link to="/" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8">
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Home
            </Link>
            
            <h1 className="text-3xl md:text-4xl font-bold mb-12">Blog</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {posts.map((post) => (
                <Link
                  key={post.slug}
                  to={`/blog/${post.slug}`}
                  className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
                >
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <p className="text-gray-600 text-sm mb-2">{post.date}</p>
                    <h2 className="text-xl font-semibold mb-2 line-clamp-2">
                      {post.title}
                    </h2>
                    <p className="text-gray-600 line-clamp-2">
                      {post.description}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          
          {/* Sidebar - Hidden on mobile */}
          <div className="hidden md:block w-[250px]">
            <div className="sticky top-8">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogIndex;