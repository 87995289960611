
import { useState, useEffect } from "react";
import { fetchLinkedInReport } from "@/utils/linkedinReportAPI";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

export const useLinkedInData = (activeProject: any | null, viewMode: "grid" | "detail") => {
  const [linkedInData, setLinkedInData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false); 
  const [retryCount, setRetryCount] = useState(0);
  const { toast } = useToast();
  const { canAccessIndividual, canAccessPro } = usePlanFeatures();
  const navigate = useNavigate();
  const { isSignedIn, isLoaded, user } = useUser();

  const MAX_RETRIES = 2; // Maximum number of retry attempts

  useEffect(() => {
    const fetchLinkedInData = async () => {
      if (activeProject && viewMode === "detail") {
        const activeProfile = activeProject.profiles.find((p: any) => p.isActive);
        
        if (activeProfile && activeProfile.url) {
          setIsLoading(true);
          try {
            console.log("LinkedIn data request - auth status:", { 
              isLoaded, 
              isSignedIn, 
              userId: user?.id,
              email: user?.primaryEmailAddress?.emailAddress,
              retryCount
            });
            
            console.log("Fetching LinkedIn data with URL:", activeProfile.url);
            
            // Check if URL is valid
            if (!activeProfile.url.includes("linkedin.com")) {
              throw new Error("Invalid LinkedIn URL. Please enter a valid LinkedIn profile URL.");
            }
            
            // Fetch the data
            const data = await fetchLinkedInReport({
              url: activeProfile.url,
              isPro: canAccessPro,
              isIndividual: canAccessIndividual,
            });
            
            if (data) {
              console.log("LinkedIn data fetched successfully:", data);
              setLinkedInData(data);
              // Reset retry counter on success
              setRetryCount(0);
              setIsRetrying(false);
            } else {
              console.log("No LinkedIn data returned from API");
              toast({
                title: "No Data Retrieved",
                description: "The API request was successful but no LinkedIn data was returned.",
                variant: "destructive",
              });
            }
          } catch (error) {
            console.error("Error fetching LinkedIn data:", error);
            
            const errorMessage = error instanceof Error 
              ? error.message 
              : "Failed to retrieve LinkedIn profile data. Please check API settings.";
            
            // Show detailed toast with retry button if we haven't exceeded max retries
            if (retryCount < MAX_RETRIES) {
              toast({
                title: "Error Fetching Data",
                description: (
                  <div>
                    <p>{errorMessage}</p>
                    <button 
                      onClick={() => {
                        setRetryCount(prev => prev + 1);
                        setIsRetrying(true);
                      }}
                      className="mt-2 px-4 py-1 bg-primary text-white rounded-md text-sm"
                    >
                      Retry
                    </button>
                  </div>
                ),
                variant: "destructive",
              });
            } else {
              // If max retries exceeded, show normal error toast
              toast({
                title: "Error Fetching Data",
                description: `${errorMessage} Maximum retry attempts exceeded.`,
                variant: "destructive",
              });
              // Reset retry counter
              setRetryCount(0);
              setIsRetrying(false);
            }
          } finally {
            setIsLoading(false);
          }
        }
      }
    };

    // If we're retrying, call the fetch function again
    if (isRetrying) {
      fetchLinkedInData();
      return;
    }
    
    // Normal data fetch on dependency changes
    fetchLinkedInData();
  }, [activeProject, viewMode, navigate, isSignedIn, isLoaded, user, canAccessPro, canAccessIndividual, toast, retryCount, isRetrying]);

  return { linkedInData, isLoading };
};
