
import { useState } from "react";
import { ProjectsGrid } from "@/components/dashboard/ProjectsGrid";
import { AddProjectDialog } from "@/components/projects/AddProjectDialog";
import { Button } from "@/components/ui/button";
import { UpgradePrompt } from "@/components/dashboard/UpgradePrompt";
import { useToast } from "@/components/ui/use-toast";

interface Project {
  id: string;
  name: string;
  profiles: any[];
}

interface ProjectsViewProps {
  projects: Project[];
  onSelectProject: (project: Project) => void;
  onRenameProject: (id: string, name: string) => void;
  onArchiveProject: (id: string) => void;
  onDeleteProject: (id: string) => void;
  canAccessPro: boolean;
  canAccessIndividual: boolean;
  maxProjects: number;
}

export const ProjectsView = ({
  projects,
  onSelectProject,
  onRenameProject,
  onArchiveProject,
  onDeleteProject,
  canAccessPro,
  canAccessIndividual,
  maxProjects
}: ProjectsViewProps) => {
  const { toast } = useToast();
  const canAddProject = projects.length < maxProjects;

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-4">Your Projects</h2>
        {canAddProject ? (
          <AddProjectDialog />
        ) : (
          <Button
            variant="ghost"
            className="text-muted-foreground"
            onClick={() => toast({
              title: "Project limit reached",
              description: `Your current plan allows a maximum of ${maxProjects} project${maxProjects > 1 ? 's' : ''}. Upgrade to add more.`,
              variant: "destructive",
            })}
          >
            Project limit reached
          </Button>
        )}
      </div>
      
      {!canAccessPro && projects.length > 0 && (
        <UpgradePrompt
          title={canAccessIndividual ? "Unlock Pro features" : "Unlock more features"}
          description={canAccessIndividual 
            ? "Get advanced insights, competitor analysis, and detailed metrics with Pro plan." 
            : "Upgrade to Individual plan to edit projects and access detailed analytics."}
          plan={canAccessIndividual ? "Pro" : "Individual"}
          className="mb-4"
        />
      )}
      
      <ProjectsGrid
        projects={projects}
        onSelectProject={onSelectProject}
        onRenameProject={onRenameProject}
        onArchiveProject={onArchiveProject}
        onDeleteProject={onDeleteProject}
      />
    </>
  );
};
