import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Post } from "@/types/post";

interface PostsTableProps {
  posts: Post[];
  sortConfig: {
    key: keyof Post;
    direction: 'asc' | 'desc';
  };
  onRequestSort: (key: keyof Post) => void;
}

export const PostsTable = ({ posts, sortConfig, onRequestSort }: PostsTableProps) => {
  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('date')}
            >
              Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('title')}
            >
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('type')}
            >
              Type {sortConfig.key === 'type' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('views')}
            >
              Views {sortConfig.key === 'views' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('likes')}
            >
              Likes {sortConfig.key === 'likes' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('comments')}
            >
              Comments {sortConfig.key === 'comments' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('shares')}
            >
              Shares {sortConfig.key === 'shares' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
            <TableHead 
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => onRequestSort('engagement')}
            >
              Engagement {sortConfig.key === 'engagement' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {posts.map((post, index) => (
            <TableRow key={index} className="hover:bg-gray-50">
              <TableCell>{post.date}</TableCell>
              <TableCell>{post.title}</TableCell>
              <TableCell>{post.type}</TableCell>
              <TableCell>{post.views.toLocaleString()}</TableCell>
              <TableCell>{post.likes}</TableCell>
              <TableCell>{post.comments}</TableCell>
              <TableCell>{post.shares}</TableCell>
              <TableCell>{post.engagement}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};