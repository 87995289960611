
import { Check } from "lucide-react";

interface PricingPlanFeatureProps {
  feature: string;
}

export const PricingPlanFeature = ({ feature }: PricingPlanFeatureProps) => {
  return (
    <li className="flex items-start">
      <div className="bg-orange-50 rounded-full p-1 mr-3 mt-1">
        <Check className="w-4 h-4 text-[#F97316]" />
      </div>
      <span className="text-gray-700">{feature}</span>
    </li>
  );
};
