
import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useSearchParams } from "react-router-dom";

interface PaymentSuccessProps {
  firstPaymentDate: string;
  onSuccess: () => void;
}

export const PaymentSuccess = ({ firstPaymentDate, onSuccess }: PaymentSuccessProps) => {
  const [searchParams] = useSearchParams();
  const discountCode = searchParams.get('discount')?.toLowerCase();

  // Custom messages based on discount code
  const getTrialMessage = () => {
    switch (discountCode) {
      case 'launch':
        return "Your 60-day free trial has started. Your first payment will be on";
      case 'special':
        return "Your 30-day free trial has started. Your first payment will be on";
      default:
        return "Your 7-day free trial has started. Your first payment will be on";
    }
  };

  return (
    <div className="p-6 text-center space-y-4">
      <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
        <Check className="w-6 h-6 text-green-600" />
      </div>
      <div className="flex justify-center mb-8">
        <img 
          src="/lovable-uploads/10e1892c-4137-45ce-9737-609c04039ed1.png" 
          alt="TrackInfluence" 
          className="h-12"
        />
      </div>
      <h2 className="text-2xl font-bold text-gray-900">
        Congratulations!
      </h2>
      <p className="text-gray-600">
        {getTrialMessage()} {firstPaymentDate}.
      </p>
      <Button onClick={onSuccess} className="w-full">
        Go to Dashboard
      </Button>
    </div>
  );
};
