import { Button } from "@/components/ui/button";
import { BarChart2, Users, TrendingUp } from "lucide-react";

export const BlogSidebar = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-linkedin-primary mb-6">Track Your Influencer ROI</h3>
      <p className="text-gray-600 mb-6 text-sm">Join for free and start optimizing your influencer campaigns today.</p>
      
      <div className="space-y-4 mb-8">
        <div className="flex items-start gap-3">
          <div className="bg-orange-50 p-1.5 rounded-lg">
            <BarChart2 className="w-4 h-4 text-[#F97316]" />
          </div>
          <div>
            <h4 className="font-semibold text-sm mb-1">Analytics Dashboard</h4>
            <p className="text-xs text-gray-600">Track campaign performance in real-time</p>
          </div>
        </div>
        
        <div className="flex items-start gap-3">
          <div className="bg-orange-50 p-1.5 rounded-lg">
            <Users className="w-4 h-4 text-[#F97316]" />
          </div>
          <div>
            <h4 className="font-semibold text-sm mb-1">Influencer Management</h4>
            <p className="text-xs text-gray-600">Organize and manage your influencer relationships</p>
          </div>
        </div>
        
        <div className="flex items-start gap-3">
          <div className="bg-orange-50 p-1.5 rounded-lg">
            <TrendingUp className="w-4 h-4 text-[#F97316]" />
          </div>
          <div>
            <h4 className="font-semibold text-sm mb-1">ROI Tracking</h4>
            <p className="text-xs text-gray-600">Measure the impact of your campaigns</p>
          </div>
        </div>
      </div>
      
      <Button className="w-full bg-[#F97316] hover:bg-[#EA580C] text-white text-sm">
        Register Free
      </Button>
    </div>
  );
};